
import React from 'react'
import './index.less'
import { pxTransform, wpsDw } from '../../../../../utils/tools'
import RubberStop from "../../../../../components/rubberStop";

const contentWrap = 'contentWrap', cateRightWrap = 'cateRightWrap'

@RubberStop(`.${contentWrap}`, `.${cateRightWrap}`)
class KindContent extends React.Component { 
  gotoDetail(parentId, title, kindItem) {
    wpsDw('button_click', 'categorytab_label_click', {
      data1: '场景',
      data2: title,
      data3: kindItem.title
    })
    const { kindId, keyword } = kindItem
    let url = ''
    let params = {}
    if (parentId === 1) { // 海报特殊处理
      url = `/search`
      params.kw = keyword
      params.pos = 'categorytablabelsearch'
    } else {
      url = `/secondLabel`
      params.kindId = kindId
    }
    this.props.gotoDetail(url, params)
  }
  renderWordItem(kindItem, {firstKindId, title}) {
    const longWordStyle = {
      width: 'auto',
      padding: `${pxTransform(19)}  ${pxTransform(27)}`
    }
    let style = {}
    if (kindItem.title.length > 4) {
      style = longWordStyle
    }
    return (
      <span
        key={kindItem.kindId || kindItem.tagId}
        style={style}
        bindtap="gotoSecondLabel"
        onClick={this.gotoDetail.bind(this, firstKindId, title, kindItem)}
      >{kindItem.title}</span>
    )
  }
  render() {
    const { data: firstKindList } = this.props
    return (
      <div className={cateRightWrap}>
        <div className={contentWrap}>
          {firstKindList.map((kindGroupItem) => (
            <div className="rightGroup" key={kindGroupItem.firstKindId}>
              <span className="rightTitle">{kindGroupItem.title}</span>
              <div className="rightWordGroup">
                {kindGroupItem.secondKinds.map(kindItem => this.renderWordItem(kindItem, kindGroupItem))}
              </div>
            </div >
          ))}
        </div>
      </div>
    )
  }
}
export default KindContent