import React from 'react';
import Toast from '../components/apiComponents/toast/index'
import Modal from '../components/apiComponents/modal/index'
import CollectModal from '../components/apiComponents/collectModal/index'
import Loading from '../components/apiComponents/loading/index'
import nprogress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style

nprogress.configure({ showSpinner: false })
const injectCompAPI = () => {
  React.showToast = Toast.showToast
  React.showModal = Modal.showModal
  React.showPreModal = CollectModal.showPreModal
  React.Loading = Loading
  React.nprogress = nprogress
}
export default injectCompAPI