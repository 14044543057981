import React from 'react'
import axios from 'axios'
import urls from './urls'
import {setUserInfo, setWpsBtnType, setWpsUserInfo, eventBus} from './eventBus';
import {judgePlatform} from '../utils/tools';

// 创建axios实例
const service = axios.create({
  timeout: 5000 // 请求超时时间
})

service.defaults.headers['Content-Type'] = 'application/json'

service.interceptors.request.use((config) => {
  if (config.method.toLocaleLowerCase() === "post") {
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    
    config.transformRequest = [function (data) {
      if (data instanceof FormData) return data
      let ret = '';
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      ret = ret.substring(0, ret.length - 1);
      return ret
    }]
  }
  if (!urls[config.url]) {
    console.warn('未找到url配置:' + config.url);
  }
  const url = urls[config.url] || config.url
  if (!(/^\/\//.test(url))) {
    config.withCredentials = true
  }
  config.url = url
  return config
}, (err) => {
  return Promise.reject(err);
})

service.interceptors.response.use((config) => {
  return config
}, (err) => {
  return err.response
})
const ua = window.navigator.userAgent.toLocaleLowerCase()
const client_type = ua.includes('micromessenger') ? 96 : (judgePlatform() === 'ios' ? '50' : '75')
const commonParams = {
  _dataType: 'json',
  _dataClientType: 3,
  client_type,
  had_gif: 0
}
const request = {
  get(url, params) {
    return service({
      url,
      method: 'get',
      params: {
        ...commonParams,
        ...params
      }
    })
  },
  post(url, data) {
    return service({
      url,
      method: 'post',
      data: {
        ...commonParams,
        ...data
      }
    })
  },
  login(redirectUrl = window.location.href) {
    const {isWps, isWechat, loginCode} = eventBus.state
    if (loginCode) {
      console.log('have logincode', loginCode);
      return new Promise((resolve, reject) => {
        request.get('wpsLogin', { code: loginCode }).then((res) => {
          // 200获取成功；-101参数错误，-105未获取到token，-110未获取到wps用户信息，-120系统异常；
          if(res.data.body.code === 200) {
            setWpsBtnType()
            setUserInfo()
            resolve({code: 200})
          } else {
            React.showToast({
              title: `登陆失败${res.data.body.code}`
            })
            reject(`登陆失败${res.data.body.code}`)
          }
          window.GBCKT.isLogined = true
        })
      })
    }
    if (isWps) {
      console.log('dont have logincode');
      return new Promise((resolve, reject) => {
        window.wps.isLogin({
          complete: (loginRet) => {
            if (loginRet.data.is_login) {
              if (window.wps.authorize) {
                window.wps.authorize({
                  scope: 'scope.userInfo',
                  complete: (authorizeRet) => {
                    console.log('login走授权');
                    if (authorizeRet.status) {
                      setWpsUserInfo().then((ret) => {
                        request.get('wpsLogin', { code:ret.login_code }).then((res) => {
                          // 200获取成功；-101参数错误，-105未获取到token，-110未获取到wps用户信息，-120系统异常；
                          if(res.data.body.code === 200) {
                            setWpsBtnType()
                            setUserInfo()
                            resolve({code: 200})
                          } else {
                            React.showToast({
                              title: `登陆失败${res.data.body.code}`
                            })
                            reject(`登陆失败${res.data.body.code}`)
                          }
                        })
                      })
                    }
                  }
                })
              } else {
                let url = window.location.origin
                request.post('oldWpsLogin', {
                  url: encodeURIComponent(url)
                }).then(response => {
                  if (response.data.body.code === 200) {
                    window.location.replace(response.data.body.data)
                  }
                })
              }
            } else {
              window.wps.login({
                complete: (ret) => {
                  if (ret.data.is_login) {
                    return this.login()
                  }
                }
              })
            }
          }
        })
      })
    } else if(isWechat) {
      console.log('微信客户端');
      // request.post('oldWpsLogin', {
      //   url: encodeURIComponent(redirectUrl)
      // }).then(response => {
      //   if (response.data.body.code === 200) {
      //     window.location.replace(response.data.body.data)
      //   }
      // })
      return new Promise((resolve, reject) => {
        request.post('loginByToken', {
          token: eventBus.state.cktLoginToken
        }).then(response => {
          if (response.data.body.code === 200) {
            resolve()
            window.location.href = redirectUrl
          } else {
            alert(`登陆失败：${response.data.body.code}`)
          }
        })
      })
    } else {
      return new Promise((resolve) => {
        resolve()
        React.showToast({title: '处理登陆中。。。', dur: 10000})
      })
    }
  }
}

export default request
