import React from 'react';
import classnames from 'classnames';
import './index.less';


import daoKeLogo from '../../assets/images/wps/daoKeLogo-white.png';
import superVipLogo from '../../assets/images/wps/superVipLogo-white.png';

import {setWpsBtnType, eventBus} from '../../config/eventBus';
import request from '../../config/request';
import {wpsDw} from '../../utils/tools';

import { CKT_MAIN_CONTENT_CLASS } from "../../config/constant";

export default function WpsContainer(a = {}, b = {}) {
  const { name = '', param = {} } = a
  const { vipBar = 'viptab_click', vipParam = {position: 'other'} } = b
  return function(WrappedComponent) {
    return class extends React.Component{
      state = {
        wpsBtnType: eventBus.state.wpsBtnType,
        isWps: eventBus.state.isWps,
        isNewWps: !!eventBus.state.loginCode
      }
      wpsDWparams = {} // wps埋点参数
      handel = (val) => {
        this.setState({
          wpsBtnType: val
        })
      }
      isRequest = false // 是否正在请求预下单接口
      componentDidMount() {
        React.nprogress.start()
        if (name) {
          let SearchParam = {}
          if (this.props.location.pathname.includes('search')) {
            if (this.props.location.state && this.props.location.state.pos) {
              SearchParam = {
                position: this.props.location.state.pos
              }
            }
            if (this.props.location.state && this.props.location.state.kw) {
              SearchParam.data4 = this.props.location.state.kw
            } else {
              SearchParam.data4 = 'none'
            }
          }
          wpsDw('page_show', name, {...param, ...SearchParam})
        }
        wpsDw('page_show', 'viptab_show', {position: vipParam.position, ...this.wpsDWparams})
        eventBus.on('wpsBtnType', this.handel)
        this.getBtnText()
        setTimeout(React.nprogress.done, 500)
      }
      componentWillUnmount() {
        eventBus.off('wpsBtnType', this.handel)
      }
      // 获取wps最新的按钮状态
      getBtnText() {
        setWpsBtnType()
      }
      // 会员购买
      BuyRiceVip() {
        wpsDw('button_click', vipBar, {position: vipParam.position, ...this.wpsDWparams})
        const {isWps, isWechat, wpsBtnType: {type}} = eventBus.state
        if (isWps) {
          window.wps.isLogin({
            complete: (res) =>{
              if (res.data.is_login) {
                this.buyVip()
              } else {
                request.login().then(() => {
                  this.getBtnText()
                })
              }
            }
          })
        } else if(isWechat) {
          if (this.isRequest) return
          this.isRequest = true
          if (window.GBCKT.isLogined) {
            const csource = eventBus.state.isWechatMin ? 'wx_docer_xcx_ckt_8344d71d0c' : 'wx_docer_ckt_8344d71d0c'
            request.post('getWpsPrePayBillno', {
              member_type: type === 0 ? 12 : 40,
              csource,
              position: eventBus.state.wpsViptabPosition
            }).then((res) => {
              if (res.data.body.code === 200) {
                this.isRequest = false
                window.wps.buyVip({
                  billno: res.data.body.data,
                })
              } else {
                this.isRequest = false
                if (res.data.body.code === -101) {
                  request.login()
                } else {
                  alert(`下单失败，请稍后再试${res.data.body.code}`)
                }
              }
            })
          } else {
            request.login()
          }
        }
      }
      // 开通会员
      openVip() {
        window.wps.openDocerVip({
          source: eventBus.state.platform === 'ios'? 'ios_docer_chuangkit' : 'android_docer_chuangkit',
          complete: (res) => {
            if (res.data.status === 0) {
              this.getBtnText()
            } else {
              window.wps.showToast({title:'支付失败'})
            }
          }
        })
      }
      // 稻壳会员购买
      buyDocerVip() {
        if (window.wps.buyDocerVip) {
          window.wps.buyDocerVip({
            source: eventBus.state.platform === 'iOS'? 'ios_docer_chuangkit' : 'android_docer_chuangkit',
            complete: (buyDocerVip) => {
              if (buyDocerVip.status) {
                this.getBtnText()
              } else {
                window.wps.showToast({title:'支付失败'})
              }
            }
          })
        } else {
          window.wps.showToast({
            title: '当前WPS版本过低，无法使用该功能，请升级到最新WPS版本后重试。'
          })
        }
      }
      // 超级会员购买
      buySuperVip() {
        if (window.wps.buySuperVip) {
          window.wps.buySuperVip({
            source: eventBus.state.platform === 'ios'? 'ios_docer_chuangkit' : 'android_docer_chuangkit',
            complete: (buySuperVip) => {
              if (buySuperVip.status) {
                this.getBtnText()
              } else {
                window.wps.showToast({title:'支付失败'})
              }
            }
          })
        } else {
          window.wps.showToast({
            title: '当前WPS版本过低，无法使用该功能，请升级到最新WPS版本后重试。'
          })
        }
      }
      // 会员购买兼容低版本
      buyVip() {
        const type = this.state.wpsBtnType // 0：普通用户； 1：稻壳会员； 2：超级会员
        let { SDKVersion, platform} = window.wps.getSystemInfo()
        if (window.wps.buyVip) {
          console.log('大于1.3.0', SDKVersion, {member_type: type === 0 ? 12 : 40})
          if (this.isRequest) return
          this.isRequest = true
          const position = `docer_pc_chuangkit_${this.props.location.pathname === '/search'?'search':'mall'}`
          request.post('getWpsPrePayBillno', {
            member_type: type === 0 ? 12 : 40,
            position
          }).then((res) => {
            if (res.data.body.code === 200) {
              console.log('billno is', res.data.body.data);
              window.wps.buyVip({
                billno: res.data.body.data,
                complete: (buyVip) => {
                  this.isRequest = false
                  console.log('buyVip', buyVip);
                  if (buyVip.status) {
                    this.getBtnText()
                  } else {
                    window.wps.showToast({title:'购买失败'})
                  }
                }
              })
            } else {
              this.isRequest = false
              console.log('预下单接口', res);
              if (res.data.body.code === -101) {
                request.login()
              } else {
                window.wps.showToast({
                  title: `下单失败，请稍后再试${res.data.body.code}`
                })
              }
            }
          })
          return
        }
        if ( window.wps.buyDocerVip &&  window.wps.buySuperVip && window.wps.isSuperVipAutoRenew) {
          if (type === 2) {
            if (platform === 'iOS') {
              window.wps.isSuperVipAutoRenew({
                complete: (ret) => {
                  if (ret.data.isAutoRenew) {
                    window.wps.showToast({
                      title: '您已开通自动续费无需手动续费'
                    })
                  } else {
                    // 购买超级会员
                    this.buySuperVip() // wps.buySuperVip
                  }
                }
              })
            } else {
              // 购买超级会员
              this.buySuperVip() // wps.buySuperVip
            }
          } else if (type === 0) {
            // 购买稻壳会员
            this.buyDocerVip() // wps.buyDocerVip
          } else {
            // 购买超级会员
            this.buySuperVip() // wps.buySuperVip
          }
          return
        }
  
        if (window.wps.openDocerVip) {
          console.log('小于1.1.0',  SDKVersion)
          if (platform === 'iOS') {
            if (type === 0) {
              this.openVip() // wps.openDocerVip
            } else {
              window.wps.showToast({
                title: '当前WPS版本过低，无法使用该功能，请升级到最新WPS版本后重试。'
              })
            }
          } else {
            // 兼容低版本会员购买
            this.openVip() // wps.openDocerVip
          }
        }
      }
      getWpsDwParams = (params = {}) => {
        this.wpsDWparams = params
      }
      render() {
        const wpsBtnTextMap = {
          0: '开通稻壳会员，全场资源免费',
          1: '升级超级会员，享受双重特权福利',
          2: '续费超级会员，享受双重特权福利'
        }
        const {wpsBtnType, isWps, isNewWps} = this.state
        const {isWechat} = eventBus.state
        const vipIcon = wpsBtnType !== 0 ? superVipLogo: daoKeLogo
        return <>
          {
            (isWps && (wpsBtnType !== -1 && wpsBtnType !== 2) && !isNewWps) && !isWechat ? 
            <div className="wps-container">
              <div className={CKT_MAIN_CONTENT_CLASS}>
                <WrappedComponent getWpsDwParams={this.getWpsDwParams} {...this.props}/>
              </div>
              <div className='wps-bottom-btn-con'>
                <div onClick={this.BuyRiceVip.bind(this)} className={classnames({'wps-icon-blue': wpsBtnType !== 0, 'wps-bottom-btn': true})}>
                  <img className='daoke-icon' src={vipIcon} alt=''/>
                  <span>{wpsBtnTextMap[wpsBtnType]}</span>
                </div>
              </div>
            </div>
            :
            <div className={CKT_MAIN_CONTENT_CLASS}>
              <WrappedComponent getWpsDwParams={this.getWpsDwParams} {...this.props}/>
            </div>
          }
        </>
      }
    }
  }
}
