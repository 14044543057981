import React from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import TabBar from './pages/tabBar/index';
import Search from './pages/search/index';
import MyDesign from './pages/myDesign/index';
import ThemeDetail from "./pages/themeDetail/index";
import DiySort from './pages/diySort/index';
import Collection from "./pages/collection/index";
import Material from "./pages/material/index";
import Contact from './pages/contact/index';
import SecondLabel from './pages/secondLabel/index';

import { setIsSearchPage, trackDesignActive } from './config/eventBus';
const routeList = [
  {
    title: '图片设计',
    path: '/tabBar',
    component: TabBar
  },
  {
    title: '搜索',
    path: '/search',
    reportPage: '搜索输入页',
    component: Search
  },
  {
    title: '专题详情',
    path: '/themeDetail',
    reportPage: '专题详情页',
    component: ThemeDetail
  },
  {
    title: '我的收藏',
    path: '/collection',
    component: Collection
  },
  {
    title: '我的图库',
    path: '/material',
    reportPage: '我的图库页',
    component: Material
  },
  {
    title: '我的设计',
    path: '/myDesign',
    component: MyDesign
  },
  {
    title: '我的分类',
    path: '/diySort',
    reportPage: '我的场景页',
    component: DiySort
  },
  {
    title: '联系客服',
    path: '/contact',
    reportPage: '联系客服页',
    component: Contact
  },
  {
    title: '图片设计',
    path: '/secondLabel',
    reportPage: '图片设计页',
    component: SecondLabel
  }
]
class App extends React.Component{
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/tabBar"/>}/>
          {routeList.map((item) => (
            <Route path={item.path} render={props => {
              if(item.reportPage){
                trackDesignActive(item.reportPage)
              }
              setIsSearchPage(props.location.pathname === '/search')
              document.title = item.title || "默认title"
              return <item.component {...props} />
            }} key={item.path} />
          ))}
        </Switch>
      </Router>
    );
  }
}

export default App;
