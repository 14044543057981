import React from 'react'
import wpsContainer from '../../components/wpsContainer/index'
import ItemElement from '../../components/itemElement/index.jsx'
import BottomTips from '../../components/bottomTips'
import Loading from '../../components/loading'
import ListContainer from '../../components/listContainer/index';
import RubberStop from "../../components/rubberStop/index";

import { pxTransform, wpsDw } from '../../utils/tools'
import { SCROLL_WRAP_ID, CKT_MAIN_CONTENT_CLASS } from "../../config/constant";

import './index.less'
@wpsContainer({}, {vipBar: 'viptab_click', vipParam: {position: 'topicdetailpage'}})
@RubberStop(`#${SCROLL_WRAP_ID}`, `.${CKT_MAIN_CONTENT_CLASS}`)
class ThemeDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      templateList: [],
      isBottomShow: false, // 尾页
      imgStyle: {}, // 图片尺寸
    }
    this.themeId = 0
    this.pageNo = 0
    this.isLoading = false

    this.reachBottom = this.reachBottom.bind(this)
  }
  async componentDidMount() {
    const { fromType, id, title = '图片设计' } = this.props.location.state
    document.title = title
    this.fromType = fromType
    this.themeId = id || 0
    wpsDw('page_show', 'topicdetailpage_show', {
      data2: `${title}_${id}`
    })
    if (fromType==='home') {
      this.getHomeTypeTemplates()
    } else if (fromType === 'banner') {
      this.getBannerTypeTemplates()
    } else {
      this.getTemplates()
    }
    this.props.getWpsDwParams && this.props.getWpsDwParams({data1: `${title}_${id}`})
  }
  // 上拉加载
  reachBottom() {
    if (this.isLoading) return
    if (this.state.isBottomShow) return
    if (this.fromType === 'home') {
      this.getHomeTypeTemplates()
    } else if (this.fromType === 'banner') {
      this.getBannerTypeTemplates()
    } else {
      this.getTemplates()
    }
  }
  async getBannerTypeTemplates() {
    try {
      this.isLoading = true
      const res0 = await React.axios.post('getWxAppSelectedTemplateInfo', { theme_id: this.themeId, page_no: ++this.pageNo })
      const res = await React.axios.get(res0.data.body.cacheUrl)
      this.isLoading = false
      const { templates: templateList } = res.data.body
      if (templateList.length > 0) {
        const stateObj = {}
        if (this.pageNo === 1) { // 初次渲染计算图片尺寸
          const templateItem = templateList[0]
          const width = 340
          const { width: originWidth, height: originHeight } = templateItem
          const height = parseFloat(originHeight) / parseFloat(originWidth) * width
          stateObj.imgStyle = { width: pxTransform(width), height: pxTransform(height), margin: pxTransform(5) }
        }
        Array.prototype.push.apply(this.state.templateList, templateList)
        stateObj.templateList = this.state.templateList
        this.setState(stateObj)
      }
    } catch (error) {
      this.setState({ isBottomShow: true })
    }
  }
  async getHomeTypeTemplates() { 
    this.isLoading = true
    const res = await React.axios.post('getWpsMobileThemeMoreTemplate', { theme_id: this.themeId, page_no: ++this.pageNo })
    this.isLoading = false
    const { code } = res.data.body
    if (code === 200) {
      const { data } = res.data.body
      if (!data || !data.templates || data.templates.length === 0) { // 空数据处理(尾页)
        this.setState({
          isBottomShow: true
        })
        return
      }
      const { templates: templateList } = data
      const stateObj = {}
      if (this.pageNo === 1) { // 初次渲染计算图片尺寸
        const templateItem = templateList[0]
        const width = 340
        const { width: originWidth, height: originHeight } = templateItem
        const height = parseFloat(originHeight) / parseFloat(originWidth) * width
        stateObj.imgStyle = { width: pxTransform(width), height: pxTransform(height), margin: pxTransform(5) }
      }
      Array.prototype.push.apply(this.state.templateList, templateList)
      stateObj.templateList = this.state.templateList
      this.setState(stateObj)
    } else {
      this.setState({isBottomShow: true})
    }
  }
  async getTemplates() {
    this.isLoading = true
    const res = await React.axios.post('getWpsMobileFeaturedTopicTemplates', { theme_id: this.themeId, page_no: ++this.pageNo })
    this.isLoading = false
    const { code } = res.data.body
    if (code === 200) {
      const data = res.data.body.data
      if (!data || !data.templates || data.templates.length === 0) { // 空数据处理(尾页)
        this.setState({
          isBottomShow: true
        })
        return
      }
      const { templates: templateList } = data
      const stateObj = {}
      if (this.pageNo === 1) { // 初次渲染计算图片尺寸
        const templateItem = templateList.templates[0]
        const width = 340
        const { width: originWidth, height: originHeight } = templateItem
        const height = parseFloat(originHeight) / parseFloat(originWidth) * width
        stateObj.imgStyle = { width: pxTransform(width), height: pxTransform(height), margin: pxTransform(5) }
      }
      Array.prototype.push.apply(this.state.templateList, templateList.templates)
      stateObj.templateList = this.state.templateList
      this.setState(stateObj)
    }
  }
  renderTemplateList() {
    const { templateList, imgStyle } = this.state
    return (
      <ListContainer className='templateBlock' reachBottom={this.reachBottom}>
        {templateList.map(templateItem => (
          <ItemElement
            wpsDw={{name: 'topicdetailpage_template_click', param: {
              position: 'topicdetailpage',
              data2: `${this.props.location.state.title}_${this.props.location.state.id}`,
              data3: `${templateItem.templateTitle}_${templateItem.designTemplateId}`,
              templateTitle: templateItem.templateTitle, themeName: this.props.location.state.title
            }}}
            key={templateItem.designTemplateId}
            data={templateItem}
            imgStyle={imgStyle}
          />
        ))}
      </ListContainer>
    )
  }
  render() {
    const { templateList, isBottomShow } = this.state
    return (
      <div className='themeDetail' id={SCROLL_WRAP_ID}>
        {this.renderTemplateList()}
        {(templateList.length >= 10 && !isBottomShow) ? <Loading />: ('')}
        {(templateList.length >= 10 && isBottomShow) ? <BottomTips />: ('')}
      </div>
    )
  }
}

export default ThemeDetail