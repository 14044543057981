
import React from 'react'
import './index.less'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"

const ThemeScroll = (props) => {
  const { themeScroll } = props
  return <>
    <div className='themeScrollBlock'>
      <h3 className='title'>热门专题</h3>
      <div className='itemWrap'>
        {themeScroll.map(themeItem => (
          <div
            className='themeItem'
            key={themeItem.id}
            onClick={() => {
              props.history.push({ pathname: `/themeDetail`, state: { id: themeItem.id,fromType: 'home' } })
            }}
          >{themeItem.themeName}</div>
        ))}
      </div>
    </div>
  </>
}
ThemeScroll.propTypes = {
  themeScroll: PropTypes.array,
}
ThemeScroll.defaultProps = {
  themeScroll: []
}
export default withRouter(ThemeScroll)
