import request from './request';
import {judgePlatform, getBaseParams, getQueryVal} from '../utils/tools';
import Tracker from "@tracker-sdk/web"

const loginCode = getQueryVal('code') || ''
const wpsViptabPosition = getQueryVal('wf') || ''
const cktLoginToken = getQueryVal('token') || ''
const extraData = getQueryVal('extraData') || ''
const ua = window.navigator.userAgent.toLocaleLowerCase()
class EventEmitter {
  state = {
    wpsUserInfo: {}, // wps用户信息
    wpsBtnType: -1, // wps底部付费条类型 0：普通用户； 1：稻壳会员； 2：超级会员
    wpsUserId: '', // wps用户id
    userInfo: {}, // 创客贴用户信息
    platform: judgePlatform(),
    isWps: ua.indexOf('wps') > -1,
    isWechat: ua.indexOf('micromessenger') > -1,
    isWechatMin: false,
    wpsViptabPosition, // wps付费条支付来源信息
    isSearchPage: false, // 是否为搜索页面,
    loginCode: loginCode, // wps通过query传递过来的code用来换取wps userInfo进行创客贴登陆
    cktLoginToken, // 通过token登陆创客贴
    extraData, // wps埋点参数
  }
  events = {}
  on(ev, fun) {
    (this.events[ev] || (this.events[ev] = [])).push(fun)
  }
  fire(ev, val) {
    const fns = this.events[ev] || []
    this.state = {
      ...this.state,
      [ev]: val
    }
    fns.forEach((item) => {
      item(val)
    })
  }
  off(n, handel) {
    const cur = this.events[n]
    for (let i = 0; i < cur.length; i++) {
      if (handel === cur[i]) {
        cur.splice(i, 1)
      }
    }
  }
  offAll() {
    this.events = {}
  }
}

const eventBus = new EventEmitter()

// 设置wps用户会员类型
const setWpsBtnType = () => {
  if (window.wps && eventBus.state.isWps) {
    window.wps.isVip({
      complete: (ret) => {
        const {is_super_vip, is_docer_vip} =  ret.data
        if (is_super_vip) {
          eventBus.fire('wpsBtnType', 2)
        } else if(is_docer_vip) {
          eventBus.fire('wpsBtnType', 1)
        } else {
          eventBus.fire('wpsBtnType', 0)
        }
      }
    })
  }
}

const setIsSearchPage = (v) => {
  eventBus.fire('isSearchPage', v)
  
}

const setIsWechatMin = (v) => {
  eventBus.fire('isWechatMin', v)
}

// 设置wps付费条支付来源信息
const setWpsViptabPosition = (v) => {
  eventBus.fire('wpsViptabPosition', v)
  window.localStorage.setItem('wpsViptabPosition', v)
}
// 设置wps端用户信息
const setWpsUserInfo = () => new Promise((resolve) => {
  if (window.wps && eventBus.state.isWps) {
    window.wps.getUserInfo({
      complete: (res) => {
        if (res.userInfo) {
          eventBus.fire('wpsUserInfo', res.userInfo)
        }
        console.log(res.login_code);
        resolve(res)
      }
    })
  } else {
    resolve()
  }
})

// 初始化wps埋点
const initWpsDw = async() => new Promise((resolve, reject) => {
  if (window.wps && window.wps.getSystemInfo && eventBus.state.isWps) {
    window.wps.getSystemInfo({
      complete(ret){
        try {
          console.log('dw_init in wpsApp chuangkit');
          window._dw.setAppKey(eventBus.state.platform === 'ios' ? '490d538cf5bce57e' : '0b6e6db092269c84')
          window._dw.setDebug(false)
          window._dw.setVersion(ret.version||'')
          window._dw.setChannel(ret.channel||'')
          resolve()
        } catch (error) {
          console.log(error);
          reject()
        }
      }
    })
  } else if (eventBus.state.isWechat) {
    console.log('dw_init in wechat chuangkit');
    // eslint-disable-next-line
    let ENV = API_ENV ? API_ENV : process.env.API_ENV;
    window._dw.setAppKey('7ff5ee1b81ca4aa5')
    window._dw.setDebug(ENV === 'moni')
    window._dw.setVersion('1.0.0')
    resolve()
  }
})

// 设置创客贴用户信息
const setUserInfo = () => {
  const callBack = () => {
    window.GBCKT.isLogined = true
    loadLikeList()
  }
  return new Promise((resolve, reject) => {
    if (eventBus.state.isWechat) {
      request.get('getUserInfo').then((response) => {
        resolve(response)
        request.post('wpsRefreshUserInfo').then((res) => {
          // 200获取成功；-101 用户未登录；-102 用户信息不存在；
          if (res.data.body.code === 200) {
            request.get('getWpsUserInfo').then((ret) => {
              if (res.data.body.code === 200) {
                eventBus.fire('userInfo', ret.data.body.data)
                const {superVipState, docerVipState} =  ret.data.body.data
                if (superVipState === 1) {
                  eventBus.fire('wpsBtnType', 2)
                } else if(docerVipState === 1) {
                  eventBus.fire('wpsBtnType', 1)
                } else {
                  eventBus.fire('wpsBtnType', 0)
                }
                callBack()
              } else {
                // request.login()
              }
            })
          } else {
            // request.login()
          }
        })
      })
    } else {
      request.get('getUserInfo').then((res) => {
        if (res.data.body.LoginTimeOut) {
          request.login().then(() => {
            resolve(res)
          })
        } else {
          resolve(res)
          if (window._dw && window.wps) {
            try {
              window._dw.setCustomPublicAttrs({
                _docer_chuangkit: res.data.body.userInfo.userId,
              })
            } catch (error) {
              console.log(error, res);
            }
          }

          // 同步上报wps和创客贴活跃数据
          window._dw.onEvent('func_result', {
            func_name: 'mob_docer_chuangkit',
            result_name: 'wps_chuangkit_active'
          })
          console.log('func_result', {
            func_name: 'mob_docer_chuangkit',
            result_name: 'wps_chuangkit_active'
          });
          const ua = window.navigator.userAgent.toLocaleLowerCase()
          const source = ua.includes('micromessenger') ? 96 : (judgePlatform() === 'ios' ? '50' : '75')
          request.post('reportWpsActive', {user_id: res.data.body.userInfo.userId, source})

          eventBus.fire('userInfo', res.data.body.userInfo)
          callBack()
        }
      })
    }
  })
}

// 获取收藏列表
const loadLikeList = () => {
  request.post('getUserData', { type: 2 }).then((res) => {
    const { code, collectedTemplateIdsStr } = res.data.body
    if (code === 1) {
      const likeList = collectedTemplateIdsStr.split(',')
      likeList.forEach(collectedIdStr => {
        window.GBCKT.likeSet.add(parseInt(collectedIdStr))
        window.GBCKT.likeSet.delete(NaN)
      })
    }
  })
}

const trackDesignActive = (name) => {
  try {
    const {userInfo: {userId, nickname, vipLevel}} = eventBus.state
    // https://chuangkit.yuque.com/ckt-rd/rra7qd/hhwp9vq8udn1lu4b?singleDoc#
    const { product_name, platform } = getBaseParams()
    let ENV = API_ENV ? API_ENV : process.env.API_ENV;
    window.$tracker = new Tracker({
      baseParams: {
        business_lines: 2,
        product_name,
        product_model_name: 0,
        platform,
        user_id: userId,
        user_vip_level: vipLevel
      },
      env: ENV
    })
    const date = Date.now()+''
    // const headerParams = await getHeaderParams()
    // window.$tracker.setBaseParams({
    //   ...headerParams,
    //   ...window.$tracker.baseParams
    // })
    const params = {
      event: 'active',
      event_name: '用户活跃',
      datetime: date,
      trigger_timing: '进入wps移动端时上报',
      params: {
        page_name: name || 'wps移动端',
        page_title: document.title,
        page_url: location.href,
        is_login: '是'
      }
    }
    window.$tracker.report(params)
    window.gio('track', 'active', {
      ...window.$tracker.baseParams,
      ...params.params,
      event_name: '用户活跃',
      datetime: date,
      trigger_timing: '进入wps移动端时上报',
    })
  } catch (error) {
    console.warn('活跃账号埋点>>error>>', error);
  }
}

export {
  eventBus,
  setWpsBtnType,
  setUserInfo,
  setWpsUserInfo,
  initWpsDw,
  setIsSearchPage,
  setWpsViptabPosition,
  setIsWechatMin,
  trackDesignActive
}