/**
* 
* @param {Array} data 数据列表
* @param {Number} lL 左列瀑布流left值
* @param {Number} rL 右列瀑布流left值
* @param {Number} outerHeight 上下两个item的间距
* @param {Number} itemWidth item的宽度
* 瀑布流
* 主要功能是将所有item的位置算好
*
*/
/* class WaterFall {
  constructor(data, lL, rL, outerHeight, itemWidth) {
    this.initData = data

  }
} */



function WaterFall(data, lL, rL, outerHeight, itemWidth) {
  this.itemWidth = itemWidth
  this.waterFallHeight = null // 瀑布流的高度
  this.waterFallList = [] // 算好位置的itemList
  this.lL = lL
  this.rL = rL
  this.outerHeight = outerHeight
  this.init.apply(this, [data, lL, rL, outerHeight])
}
WaterFall.prototype.init = function (data) {
  this.waterFallList = []
  // 实例化两列瀑布流
  this.L = new Col(this.lL, this.outerHeight)
  this.R = new Col(this.rL, this.outerHeight)
  this.compute(data)
}

WaterFall.prototype.compute = function(data) {
  // 循环计算每列元素的top,left
  let len = data.length
  let L = this.L
  let R = this.R
  for (let i = 0; i < len; i++) {

    // 计算item实际的宽度和高度
    data[i].imgHeight = parseInt(data[i].design_height || data[i].height) * (this.itemWidth / parseInt(data[i].design_width || data[i].width))
    data[i].itemWidth = this.itemWidth

    if (L.getHeight() === R.getHeight() && L.getHeight() === 0) {
      data[i].left = L.left
      data[i].top = 0
      L.arr.push(data[i])
    } else if (L.getHeight() < R.getHeight() || L.getHeight() === R.getHeight()) {
      data[i].top = L.getHeight()
      data[i].left = L.left
      L.arr.push(data[i])
    } else {
      data[i].top = R.getHeight()
      data[i].left = R.left
      R.arr.push(data[i])
    }
  }
  this.waterFallHeight = Math.max(L.getHeight(), R.getHeight())
  this.waterFallList.push(...data)
}

WaterFall.prototype.add = function(data) {
  this.compute(data)
  return this
}

WaterFall.prototype.clear = function () {
  this.init([])
  return this
}

function Col(left, outerHeight) {
  this.arr = []
  this.left = left
  this.outerHeight = outerHeight
}
Col.prototype.getHeight = function () {
  let len = this.arr.length
  return len > 0 ? this.arr[len - 1].top + this.arr[len - 1].imgHeight + this.outerHeight : 0
}

export default WaterFall
