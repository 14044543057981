import React from 'react';
import totopIcon from '../../assets/images/pub/all_toTop.png';
import './index.less'
import {scrollToTop} from '../../utils/tools';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"
import { COLLECT_PAGE_CLASS, MATERIAL_PAGE_CLASS } from "../../config/constant";
import {eventBus} from '../../config/eventBus';

@withRouter
class ToTop extends React.Component {
  static propTypes = {
    containerRef: PropTypes.object.isRequired
  }
  state = {
    bottom: null,
    isNewWps: !!eventBus.state.loginCode
  }
  currentPage = ''
  bottomConfig = {
    wpsHeight: 0,
    tabBarH: 0,
    capacityH: 0,
  }
  componentDidMount() {
    const ua = window.navigator.userAgent.toLocaleLowerCase()
    if (ua.includes('wps') || ua.includes('micromessenger')) {
      this.bottomConfig.wpsHeight = this.state.isNewWps ? 1 : 1.3
    }
    if (this.props.location.pathname.includes('tabBar')) {
      this.bottomConfig.tabBarH = 1
    }
    if (this.props.location.pathname.includes('material')) {
      this.bottomConfig.capacityH = 1
      this.currentPage = 'material'
    }
    if (this.props.location.pathname.includes('collection')) {
      this.bottomConfig.capacityH = 1
      this.currentPage = 'collection'
    }

    this.setState({
      bottom: 0.3 + this.bottomConfig.wpsHeight + this.bottomConfig.tabBarH + this.bottomConfig.capacityH
    })
  }
  toTop = this.toTop.bind(this)
  toTop() {
    let containerRef = this.props.containerRef
    if (this.currentPage === 'material') {
      containerRef = document.getElementsByClassName(MATERIAL_PAGE_CLASS)[0]
    }
    if (this.currentPage === 'collection') {
      containerRef = document.getElementsByClassName(COLLECT_PAGE_CLASS)[0]
    }
    scrollToTop(containerRef)
  }
  render() {
    const style = {'bottom': this.state.bottom + 'rem'}
    return <div className='toTop' style={style} onClick={this.toTop}>
      <img src={totopIcon} className='toTop-icon' alt=''/>
    </div>
  }
}

export default ToTop