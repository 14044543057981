import React from 'react';
import request from '../../config/request';
import {setUserInfo, eventBus} from '../../config/eventBus';

export default function Login(WrappedComponent) {
  return class extends React.Component{
    state = {
      isLogin: false
    }
    componentDidMount() {
      const {isWps, isWechat} = eventBus.state
      if (!window.GBCKT.isLogined) {
        if (isWps && window.wps.isLogin) {
          window.wps.isLogin({
            complete: (loginRet) => {
              if (loginRet.data.is_login) {
                request.login().then(() => {
                  this.setState({
                    isLogin: true
                  })
                })
              } else {
                window.wps.login({
                  complete: (ret) => {
                    if (ret.data.is_login) {
                      setUserInfo().then(() => {
                        request.login().then(() => {
                          this.setState({
                            isLogin: true
                          })
                        })
                      })
                    }
                  }
                })
              }
            }
          })
        }
        if (isWechat) {
          if (!!eventBus.state.wpsUserInfo) {
            console.log(eventBus);
            this.setState({
              isLogin: true
            })
          } else {
            request('getUserInfo').then(() => {
              request.login()
            })
          }
        }
      } else {
        this.setState({
          isLogin: true
        })
      }
    }
    render() {
      return <>
        {this.state.isLogin ? <WrappedComponent {...this.props}/> : <></>}
      </>
    }
  }
}