import React from 'react'
import {Route, Link, Redirect, useRouteMatch} from "react-router-dom";
import classnames from 'classnames';

// 组件
import Home from './home/index';
import Category from './category/index';
import ThemePage from './themePage/index';
import UserCenter from './userCenter/index';

// 底部icon
import homeIcon from '../../assets/images/tabBar/home.png';
import homeActiveIcon from '../../assets/images/tabBar/home-active.png';
import cateIcon from '../../assets/images/tabBar/cate.png';
import cateActiveIcon from '../../assets/images/tabBar/cate-active.png';
import themeIcon from '../../assets/images/tabBar/theme.png';
import themeActiveIcon from '../../assets/images/tabBar/theme-active.png';
import myIcon from '../../assets/images/tabBar/my.png';
import myActiveIcon from '../../assets/images/tabBar/my-active.png';

import './index.less'
import {wpsDw} from '../../utils/tools';
import { trackDesignActive } from '../../config/eventBus';
function Tabar(props) {
  const { pathname } = props.location
  let { path, url } = useRouteMatch();
  console.warn('💰💰💰 ~ url>>', url, pathname)
  console.log(props, useRouteMatch());
  const tabBar = [{
      path: '/home',
      tabName: 'home',
      text: '首页',
      reportPage: '首页',
      iconPath: homeIcon,
      activeIconPath: homeActiveIcon,
      component: Home
    }, {
      path: '/category',
      tabName: 'category',
      text: '分类',
      reportPage: '模板分类页',
      iconPath: cateIcon,
      activeIconPath: cateActiveIcon,
      component: Category
    }, {
      path: '/theme',
      tabName: 'topics',
      text: '精选专题',
      reportPage: '模板精选专题页',
      iconPath: themeIcon,
      activeIconPath: themeActiveIcon,
      component: ThemePage
    }, {
      path: '/userCenter',
      tabName: 'mine',
      text: '我的',
      reportPage: '个人中心页',
      iconPath: myIcon,
      activeIconPath: myActiveIcon,
      component: UserCenter
  }]
  tabBar.map(item => {
    if(pathname.includes(item.path)){
      trackDesignActive(item.reportPage)
    }
  })
  return (
    <div className="tabBar">
      <div className="tabBar-main-content">
        <Route path={path} exact render={() => <Redirect to={`${path}/home`}/>}/>
        {tabBar.map((item) => (
          <Route path={`${path}${item.path}`} key={item.path} render={props => {
            document.title = item.text || "图片设计"
            return <item.component {...props} />
          }}/>
        ))}
      </div>
      <div className="tabar-con">
        {tabBar.map((item, index) => {
          const {path, activeIconPath, iconPath, text} = item
          const active = pathname === `${url}${path}`
          return <Link to={`${url}${path}`} onClick={() => {
            wpsDw('button_click', 'tab_click', { data1: item.tabName })
          }} className={classnames({'tab-item': true, active})} key={index}>
            <img src={active ? activeIconPath : iconPath} alt='chuangkit' />
            <span>{text}</span>
          </Link>
        })}
      </div>
    </div>
  );
}
export default Tabar