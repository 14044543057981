import React from 'react'
import classnames from 'classnames';
import wpsContainer from '../../components/wpsContainer/index';
import fixedColWaterFall from '../../utils/fixedColWaterFall';
import './index.less'
import noResult from '../../assets/images/pub/no-design.png';
import deleteIcon from '../../assets/images/pub/delete.png';

import request from '../../config/request';

import ListContainer from '../../components/listContainer/index';
import login from '../../components/login';
import Image from '../../components/image/index';
import BottomTips from '../../components/bottomTips/index';
import { wpsDw , isTablet, getQueryVal } from '../../utils/tools';
import { eventBus } from '../../config/eventBus';
import {process} from 'ckt_oss'
@login
@wpsContainer({name: 'mydesignpage_show'}, {vipBar: 'viptab_click', vipParam: {position: 'mydesignpage'}})
class MyDesign extends React.Component {
  state = {
    activeKindName: '全部场景',
    showMoreKind: false,
    allKindData: [],
    listQuery: {
      page_no: 1,
      page_size: 15,
      time_order: 1,
      kind_id: 0
    },
    totalCount: -1,
    designList: [],//waterfall之后的模板数组
    waterFallHeight: 0,
    showBottom: false, // 是否显示底部
    showDeleteMask: false, // 删除模板的蒙层
    deleteDesignIds: [],
    isToTopShow: true, // 在需要时隐藏
    templateList:[],//模板数组
    currentScreenWidth:0
  }
  observer = null
  toggleDropDown = this.toggleDropDown.bind(this)
  setCurrentKind = this.setCurrentKind.bind(this)
  toggleDelete = this.toggleDelete.bind(this)
  deleteDesigns = this.deleteDesigns.bind(this)
  orientationChangeHandler=this.getLayoutTemplateList.bind(this)

  componentDidMount() {
    this.getAllKindData()
    this.getUserDesign()
    window.addEventListener('orientationchange',this.orientationChangeHandler)
  }
  componentWillUnmount(){
    window.removeEventListener('orientationchange',this.orientationChangeHandler)
  }
  // 请求二级分类数据
  async getAllKindData() {
    const res = await React.axios.get('getDesignCountGroupByKind')
    const code = res.data.body.code  // -1 参数错误；-5 未登录；1 获取成功
    if (code === 1) {
      let countInfo = res.data.body.countInfo, num = 0
      countInfo = countInfo.filter(item => item.designCount > 0)
      countInfo.forEach(e => {
        num += e.designCount
      })
      countInfo.unshift({
        kindTitle: "全部场景",
        kindId: 0,
        designCount: num
      })
      this.setState({
        allKindData: countInfo
      })
    } else if (code === -5) {
      request.login()
    }
  }
  // 切换二级分类下拉弹框
  toggleDropDown() {
    if (this.state.showDeleteMask) return
    this.setState((pre) => {
      return {showMoreKind: !pre.showMoreKind}
    })
  }
  // 设置当前选中的二级分类
  setCurrentKind(val) {
    const {kindTitle, kindId} = val
    this.setState({
      activeKindName: kindTitle,
      listQuery: {
        ...this.state.listQuery,
        kind_id: kindId,
        page_no: 1,
      }
    }, () => {
      this.toggleDropDown()
      this.getUserDesign()
    })
  }
  // 切换删除设计蒙层
  toggleDelete() {
    this.setState((pre) => {
      return {
        showDeleteMask: !pre.showDeleteMask,
        isToTopShow: pre.showDeleteMask,
      }
    })
  }
  // 选择设计删除
  selectDeleteDesign(ev, des) {
    ev.stopPropagation()
    if (this.state.deleteDesignIds.length >= 20 && !des.checked) {
      React.showToast({
        title: '单次操作最多可选20个'
      })
      return
    }
    this.setState((pre) => {
      pre.designList.forEach((item) => {
        if (item.designId === des.designId) {
          item.checked = !item.checked
          if (item.checked) {
            pre.deleteDesignIds.push(des.designId)
          } else {
            pre.deleteDesignIds = pre.deleteDesignIds.filter(v => v !== des.designId)
          }
        }
      })
      const {deleteDesignIds, designList} = pre
      return {
        deleteDesignIds,
        designList
      }
    })
  }
  // 删除设计
  deleteDesigns() {
    const deleteIds = this.state.deleteDesignIds.join(',')
    request.get('delUserDesign', {design_ids: deleteIds}).then((res) => {
      console.log(res);
      if (res.data.body.code === 1) {
        this.setState({
          deleteDesignIds: [],
          showDeleteMask: false,
          listQuery: {
            ...this.state.listQuery,
            page_no: 1,
          },
          designList: []
        }, () => {
          this.getAllKindData()
          setTimeout(() => {
            this.getUserDesign()
          }, 1000);
        })
      }
    })
  }
  // 获取当前用户的设计
  async getUserDesign(isloadMore = false) {
    const res = await React.axios.get('queryUserDesignsV2', this.state.listQuery)
    console.log({ resultcc: res })
    const code = res.data.body.code
    // -1参数错误；-2未登录；-3权限不足；-4用户暂无设计记录；1获取用户设计成功
    if (code === -4) {
      if (this.state.designList.length === 0) {
        this.setState({
          totalCount: 0
        })
      } else {
        this.setState({
          showBottom: true
        })
      }
    } else if (code === 200) {
      const { count: totalCount, list } = res.data.body.data
      const limitSceneIds = ['447', '35', '31', '127', '128'] // 不可编辑场景id限制
      list.forEach((item) => {
        // item.imgUrl += `?v=${Date.now()}&x-oss-process=image/resize,w_300/format,jpg` 
        item.imgUrl = process(item.imgUrl, `v=${Date.now()}&x-oss-process=image/resize,w_300/format,jpg`) 
        item.checked = false
        const date = new Date(Number(item.createTime))
        const month = (date.getMonth() + 1).toString()
        const day = date.getDate().toString()
        item.time = `${ month.padStart(2, '0') }-${ day.padStart(2, '0') }`
        item.processing = !(Boolean(item.lastThumbTime) || Boolean(item.lastThumbTimeV2))
        item.canEdit = !!(item.isWxSmallAppShow === 1 || item.isWxSmallAppShow === 3)
      })
      if(isloadMore){
        this.setState({
          templateList:[...this.state.templateList,...list]
        })
      }else{
        this.setState({
          templateList:list
        })
      }
      this.getLayoutTemplateList()
      console.log('setCount', totalCount)
      this.setState({ totalCount })
    } else if (code === -2) {
      request.login()
    }
  }
  
  getLayoutTemplateList(){
    //手机屏2列 pad竖屏3列 pad横屏4列
    let listSum=2
    if(isTablet()){
      console.log('window.orientation',window.orientation)
      if(window.orientation === 90 || window.orientation === -90){ //横向
        listSum=4
      }else{ //纵向
        listSum=3
      }
    }
    let screenWidth
    if(listSum===4){
      screenWidth=window.screen.height>window.screen.width?window.screen.height:window.screen.width
    }else{
      screenWidth=window.screen.width
    }
    this.setState({
      currentScreenWidth:screenWidth
    })
    const marginX=24 / 750 * screenWidth
    const marginY = 21 / 750 * screenWidth
    const mainwidth=screenWidth-2*marginX //减去外部两侧间距
    const {waterFallList, waterFallHeight} = fixedColWaterFall({
      eleData: this.state.templateList,
      listSum: listSum,
      boxRect: {
        width: mainwidth / listSum - marginX + marginX / listSum, //减去内侧边距
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        marginLeft: marginX,
        marginTop: marginY
      }
    })
    this.setState({
      waterFallHeight,
      designList: waterFallList
    })
  }

  transformTime(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return year + '.' + month + '.' + day
  } 
  async goDesign({designId, canEdit}) {
    wpsDw('button_click', 'mydesignpage_template_click', {
      position: 'mydesignpage'
    })
    const res = await React.axios.get('getDesignOpenSwitch', {design_id: designId} )
    const { code, data } = res.data.body // -101参数错误;-102未登录;-103无模板记录;200成功
    if(code === 200){
      if(!data.flag){
        React.showModal({
          title: '提示',
          content:  data.paperwork,
          showCancel: false,
          confirmText: '我知道了',
        })
        return
      }
    }
    const {isWechatMin, extraData, wpsViptabPosition} = eventBus.state
    let url = `${window.location.origin}/mdesign?d=${designId}&cktFrom=1&extraData=${extraData}&wf=${wpsViptabPosition}&startTime=${new Date().getTime()}`
    if(getQueryVal('entry_params')){
      // let entryParams =JSON.parse(decodeURIComponent(getQueryVal('entry_params')))
      // if(window.sessionStorage.getItem('shareFrom') && entryParams.first_entry == 'null'){
      //   entryParams.first_entry = window.sessionStorage.getItem('shareFrom')
      //   url = `${url}&entry_params=${JSON.stringify(entryParams)}`
      // }else{
        url = `${url}&entry_params=${getQueryVal('entry_params')}`
      //}
    }
    if(getQueryVal('from')){
      url = `${url}&from=${getQueryVal('from')}`
    }
    if(getQueryVal('position')){
      url = `${url}&position=${getQueryVal('position')}`
    }
    if(getQueryVal('entry_to')){
      url = `${url}&entry_to=${getQueryVal('entry_to')}`
    }
    if(getQueryVal('state')){
      url = `${url}&state=${getQueryVal('state')}`
    }
    if (isWechatMin) {
      React.wx.miniProgram.navigateTo({
        url : '/pages/openWin/openWin?url=' + window.encodeURIComponent(url)
      })
    } else {
      window.location.href = url
    }
  }
  render() {
    const { activeKindName, showMoreKind, allKindData, totalCount, designList, waterFallHeight, showBottom, showDeleteMask, deleteDesignIds, isToTopShow ,currentScreenWidth} = this.state
    const selectKindClass = classnames({
      'select-kind': true,
      active: showMoreKind
    })
    const marginX=24 / 750 * currentScreenWidth
    const designListConStyle = {height: waterFallHeight ,marginLeft:marginX,marginRight:marginX}
    const deleteBtnClass = classnames({
      'delete-icon': true,
      'active': showDeleteMask
    })
    return <div className="myDesign-con">
      { designList.length > 0 && <div className="select-con">
        <div className="drop-down-header">
          <p className={selectKindClass} onClick={this.toggleDropDown}>{activeKindName}</p>
          { !showMoreKind && <p className={deleteBtnClass} onClick={this.toggleDelete}>{ !showDeleteMask && '删除' }</p> }
        </div>
        { // 二级分类下拉弹框
          showMoreKind && <div className="drop-down-body">
            <div>
              {allKindData.map(item => (
                <p key={item.kindId} onClick={() => this.setCurrentKind(item)}>{item.kindTitle} （ {item.designCount} ） </p>
              ))}
            </div>
          </div>
        }
      </div>}
      { showMoreKind && <div onClick={this.toggleDropDown} className="drop-down-mask"></div> }
      <ListContainer isToTopShow={isToTopShow} reachBottom={() => {
        this.setState({
          listQuery: {
            ...this.state.listQuery,
            page_no: this.state.listQuery.page_no + 1
          }
        }, () => this.getUserDesign(true))
      }}>
        <div className="design-list-con" style={designListConStyle}>
          {
            designList.map((item, index) => {
              const { designId, imgUrl, processing, checked, time, canEdit} = item
              const {top, left, itemWidth, imgHeight} =item.boxStyle
              const style = {
                top, left, width: itemWidth, height: imgHeight
              }
              const waterFallClass = classnames({
                'waterFall-item': true,
                'thumb_processing': processing,
              })
              const deleteMask = classnames({
                'delete-mask': true,
                'active': checked
              })
              
              return <div onClick={this.goDesign.bind(this, item)} className={waterFallClass} key={designId} style={style}>
                { processing ?
                  <span>缩略图正在生成中...</span>
                    :
                  <Image index={index} lazyLoad src={imgUrl}/>
                }
                <div className='edit-time-con'><p className='edit-time'>{time}</p></div>
                {!canEdit && <div className='uneditable'>
                  <div className="tip-con" style={{position:'absolute'}}>
                    <div className='text'>该模板类型</div>
                    <div className='text'>仅能在电脑上编辑</div>
                  </div>
                </div>}
                { showDeleteMask && <div className={deleteMask} onClick={(ev) => this.selectDeleteDesign(ev, item)}></div> }
              </div>
            })
          }
          { // 暂无设计
            totalCount === 0 && <div className='no-result-con'>
              <img className='img' src={noResult} alt="" />
              <div className='no-text'>你还没有任何作品</div>
              <div className='change-text'>快去选择一个精美模板创建设计吧</div>
              {/* <div onClick={() => this.props.history.push('/tabBar/home')} className='create-btn'>立即创建</div> */}
            </div>
          }
          { // 删除按钮
            (deleteDesignIds.length > 0 && showDeleteMask ) && <div onClick={this.deleteDesigns} className="delete-design-btn">
              <img className='delete-icon' src={deleteIcon} alt=""/>
              <span>删除</span>
            </div>
          }
        </div>
        { // 滑动到底部
          (showBottom && designList.length > 10) && <BottomTips/>
        }
      </ListContainer>
    </div>
  }
}

export default MyDesign