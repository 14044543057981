/* eslint-disable react/jsx-indent-props */
import React from 'react'
import Magnifier from '../../assets/images/pub/index-search-icon.png'
import './index.less'

const SearchBtn = (props) => (
  <div className='index-search-wrap' {...props} bindtap='indexSearchConfirm'>
    <img src={Magnifier} alt='' />
    <span>100000+ 免费模板任你搜索</span>
  </div>
)
export default SearchBtn