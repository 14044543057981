import React from 'react'
import wpsContainer from '../../../components/wpsContainer/index'
import SearchBtn from '../../../components/searchBtn/index'

import CatetNav from "./components/leftNav/index";
import KindContent from "./components/kindContent/index";
import CateContent from "./components/cateContent/index";

import { wpsDw } from "../../../utils/tools";

import './index.less'

@wpsContainer({name: 'categorytab_show'}, {vipBar: 'viptab_click', vipParam: {position: 'categorytab'}})
class Category extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cateList: [], // 非场景的分类
      firstKindList: [], // 场景
      currentIndex: 0, // 左侧当前分类
    }
    this.changeNav = this.changeNav.bind(this)
    this.renderContent = this.renderContent.bind(this)
    this.gotoDetail = this.gotoDetail.bind(this)
  }
  componentDidMount() {
    this.getCategoryData()
    this.getMobileFirstKindsInfo()
  }
  // 非场景分类
  async getCategoryData() {
    const res = await React.axios.post('getTemplateSelectedBlock', { client_type: 5 })
    const { code, data: cateList } = res.data.body
    if (code === 200) {
      this.mapIconName(cateList)
      this.setState({
        cateList
      })
    }
  }
  // 场景分类
  async getMobileFirstKindsInfo() {
    const res = await React.axios.post('getMobileFirstKindsInfo')
    const { code, data: firstKindList } = res.data.body
    if (code === 200) {
      firstKindList.forEach((item, index) => {
        if (item.firstKindId === 1) { // 去除视频
          item.title = '手机海报'
        }
        if (item.firstKindId === 5) { // 去除视频
          firstKindList.splice(index, 1)
        }
      })
      this.getChildrenData(firstKindList)
    }
  }
  // 左侧图标
  mapIconName(cateList) {
    const nameConfig = {
      '场景': 'cate0',
      '用途': 'cate1',
      '行业': 'cate2',
      '节日': 'cate3',
      '热点': 'cate4',
    }
    cateList.forEach((item) => {
      item.imgSrc = nameConfig[item.tagName]
    })
  }
  // 获取一级分类的子标签
  getChildrenData(firstKindList) {
    const pAllList = []
    firstKindList.forEach((item) => {
      if (item.firstKindId === 1) { //海报
        const p = this.getHaiBaoTag()
        pAllList.push(p)
      } else {
        const p = this.getThemeTag(item.firstKindId)
        pAllList.push(p)
      }
    })
    const pAll = Promise.all(pAllList)
    pAll.then((value) => {
      for (let i = 0; i < firstKindList.length; i++) {
        firstKindList[i].secondKinds = value[i]
      }
      this.setState({
        firstKindList,
      })
    })
  }
  // 获取海报标签
  async getHaiBaoTag() {
    return new Promise(async (resolve) => {
      const res = await React.axios.post('getDesignKindTags', { kind_id: 166 })
      let { data: haibaoSecondKindList } = res.data.body
      haibaoSecondKindList = haibaoSecondKindList || []
      resolve(haibaoSecondKindList)
    })
  }
  // 主题分类标签
  getThemeTag(firstKindId) {
    return new Promise(async (resolve) => {
      const res = await React.axios.post('getMobileSecondKindsInfo', { first_kind_id: firstKindId })
      const { code, data: secondKindList } = res.data.body
      if (code === 200) {
        resolve(secondKindList)
      }
    })
  }
  gotoDetail(url, state) {
    this.props.history.push({ pathname: url, state })
  }
  // 点击分类导航
  changeNav(activeIndex) {
    this.setState({
      currentIndex: activeIndex
    })
  }
  renderContent() {
    const { currentIndex, cateList, firstKindList } = this.state
    if (currentIndex === 0) {
      return <KindContent data={firstKindList} gotoDetail={this.gotoDetail}/>
    } else {
      return <CateContent
        firstLevel={cateList[currentIndex - 1].tagName}
        data={cateList[currentIndex - 1].firstKinds}
        gotoDetail={this.gotoDetail}
      />
    }
  }
  render() {
    const { currentIndex, cateList } = this.state
    return (
      <div className='catePage'>
        <div className='top'><SearchBtn onClick={() => {
          wpsDw('button_click', 'categorytab_searchbar_click')
          this.props.history.push({pathname: '/search', state: {pos: 'categorytabsearch'}})
        }} /></div>
        <CatetNav data={cateList} defaultActive={currentIndex} onClick={this.changeNav}/>
        {this.renderContent()}
      </div >
    )
  }
}

export default Category