import React from 'react';
import ReactSwipe from 'react-swipe';
import {wpsDw} from '../../../../../utils/tools';
import { withRouter } from "react-router-dom"

class Banner extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.banner ===nextProps.banner) {
      return false
    }
  }
  gotoDedail(bannerItem) {
    let bannerUrl = bannerItem.bannerUrl
    if (/http/.test(bannerUrl)) {
      window.location.href = bannerUrl
      return
    }
    const patternKind = /kindId=(\d+)/i
    const patternTheme = /theme_id=(\d+)/i
    const matchesTheme = patternTheme.exec(bannerItem.bannerUrl)
    const matchesKind = patternKind.exec(bannerItem.bannerUrl)
    wpsDw('button_click', 'hometab_banner_click', {
      data3: `${bannerItem.bannerName}_${bannerItem.id}`
    })
    if (matchesTheme.length>1) {
      this.props.history.push({ pathname: '/themeDetail', state: { id: matchesTheme[1], fromType: 'banner' } })
      return
    }
    if (matchesKind.length > 1) {
      this.props.history.push({ pathname: '/secondLabel', state: { kindId: matchesKind[1] } })
    }
  }
  render() {
    return (
      <ReactSwipe
        className="bannerWrap"
        swipeOptions={{
          continuous: true,
          auto: 4000,
          callback: (index) => {
            this.props.onChangeDot(index)
          }
        }}
        ref={el => (this.reactSwipeEl = el)}
      >
        {this.props.banner.map((item, index) => {
          const url = 'https:' + item.thumbUrl
          return (
            <div key={index} className='bannerItem'><img className='bannerImg' src={url} alt="" onClick={this.gotoDedail.bind(this, item)} /></div>
          )
        })}
      </ReactSwipe>
    );
  }
}

export default withRouter(Banner)