
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames';
import CloseIcon from '../images/close.png'
import './index.less'

class CollectionHead extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    isInDelect: PropTypes.bool, 
  }
  static defaultProps = {
    kindList: [],
    isInDelect: false
  }
  constructor(props) {
    super(props)
    this.state = {
      isAllKindShow: false, // 是否显示下拉菜单
      currentKindIndex: 0, // 当前场景索引
      currentTitle: '全部场景', // 当前选择的场景
    }

    this.toggoleKindMenu = this.toggoleKindMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.stopPropgation = this.stopPropgation.bind(this)
    this.toggoleDeleteState = this.toggoleDeleteState.bind(this)
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
  }
  toggoleKind(currentKindIndex) {
    const { currentKindIndex: prevKindIndex } = this.state
    const { kindList } = this.props
    this.setState({
      currentKindIndex,
      isAllKindShow: false,
      currentTitle: kindList[currentKindIndex].kindTitle
    })
    const kindId = this.props.kindList[currentKindIndex].kindId

    if (currentKindIndex !== prevKindIndex) {
      this.props.onChangeKind(kindId)
    }
  }
  toggoleKindMenu(e) {
    if (this.props.isInDelect) return
    this.setState({
      isAllKindShow: !this.state.isAllKindShow
    })
  }
  closeMenu() {
    this.setState({
      isAllKindShow: false
    })
  }
  stopPropgation(e) {
    e.stopPropagation()
  }
  toggoleDeleteState() {
    this.props.onChangeDelete(!this.props.isInDelect)
  }
  render() {
    const { kindList, isInDelect } = this.props
    const { isAllKindShow, currentTitle } = this.state
    const wrapClass = classnames({
      collectionHeadBlock: true,
      mask: isAllKindShow
    })
    return (
      <div className={wrapClass} onClick={this.closeMenu}>
        {isAllKindShow ? (
          <div className='menuWrap' onClick={this.stopPropgation}>
            <p className='currentKind' onClick={this.toggoleKindMenu}>{currentTitle}</p>
            <div className='contentBlock'>
              {kindList.map((kindItem, kindIndex) => (
                <p className='kindItem' key={kindItem.kindId} onClick={this.toggoleKind.bind(this, kindIndex)}>{kindItem.kindTitle}<span className='count'>（{kindItem.templateCount}）</span></p>
              ))}
            </div>
          </div>
        ) : (
            <div className='navTop' onClick={this.stopPropgation}>
              <p className='currentKind' onClick={this.toggoleKindMenu}>{currentTitle}</p>
              <div className='deleteBtn' onClick={this.toggoleDeleteState}>{isInDelect ? <img className='closeIcon' src={CloseIcon} alt='' /> : '取消收藏'}</div>
            </div>
          )}

      </div>
    )
  }
}
export default CollectionHead