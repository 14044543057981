import React from 'react';
import { withRouter } from "react-router-dom"
import classnames from 'classnames';
import './index.less'
import {nodeAlign, scrollToTop, wpsDw} from '../../utils/tools';

import request from '../../config/request';

import wpsContainer from '../../components/wpsContainer/index';
import SearchBtn from '../../components/searchBtn/index'
import ListContainer from '../../components/listContainer/index';
import ItemElement from '../../components/itemElement/index';
import BottomTips from '../../components/bottomTips/index';
import RubberStop from "../../components/rubberStop/index";

import noResult from '../../assets/images/pub/no-design.png';
import { SCROLL_WRAP_ID,CKT_MAIN_CONTENT_CLASS } from "../../config/constant";
@withRouter
@wpsContainer({}, {vipBar: 'viptab_click', vipParam: {position: 'categorypage'}})
@RubberStop(`#${SCROLL_WRAP_ID}`, `.${CKT_MAIN_CONTENT_CLASS}`)
class SecondLabel extends React.Component{
  state = {
    tagsData: [], // 顶部标签数据
    selectTagIndex: 0, // 顶部所选择标签index
    sort_type: 0,  // 排序类型
    price_type: 2, // 价格排序类型
    currentKind: null, // 当前的分类id
    sortOpts: [
      { sort_type: 0, title: '最热' },
      { sort_type: 1, title: '最新' },
      { sort_type: 2, title: '最多使用' },
      { sort_type: 3, title: '最多收藏' },
    ],
    showPriceOpt: false,
    priceOpts: [
      { price_type: 2, title: '全部' },
      { price_type: 0, title: '免费' },
      { price_type: 1, title: '付费' },
    ],
    tempList: [],
    totalCount: -1,
    imgStyle: null,
  }
  listQuery = {
    page_size: 20,
    page_no: 1
  }
  secondCon = React.createRef()
  componentDidMount() {
    const {state = {}} = this.props.location
    const {kindTitle = '图片设计', kindId} = state
    document.title = kindTitle
    if (kindId) {
      this.getTopTagData(kindId)
    } else {
      React.showToast({title: '当前分类暂无模板'})
      this.props.history.goBack()
    }
    this.props.getWpsDwParams && this.props.getWpsDwParams({data1: `${kindTitle}_${kindId}`})
    wpsDw('page_show', 'categorypage_show', {
      data1: `${kindTitle}_${kindId}`,
      data2: `全部_0`
    })
  }
  // 获取顶部标签数据
  getTopTagData(second_kind_id) {
    request.get('getDesignType', {second_kind_id}).then((res) => {
      if (!res.data.body) {
        React.showToast({
          title: '服务器未响应',
        })
        return
      }
      const repoTag = res.data.body.secondKindInfo.repoTag
      let tagsData = []
      if (repoTag.use.length>0){
        repoTag.use.forEach((item) => {
          item.use_tag_id = item.tagId
          delete item.tagId
        })
        tagsData = [{name: '全部', use_id: 0}, ...repoTag.use]
      } else if (repoTag.industry.length > 0){
        repoTag.industry.forEach((item) => {
          item.industry_tag_id = item.tagId
          delete item.tagId
        })
        tagsData = [{name: '全部', tag_id: 0}, ...repoTag.industry]
      } else if (repoTag.style.length > 0) {
        repoTag.style.forEach((item) => {
          item.style_tag_id = item.tagId
          delete item.tagId
        })
        tagsData = [{name: '全部', style_id: 0}, ...repoTag.style]
      } else {
        tagsData = []
      }
      this.setState({
        tagsData,
        currentKind: second_kind_id
      }, () => {
        this.toggleSecondKind(0, false)
      })
    })
  }
  // 切换顶部二级分类数据
  toggleSecondKind(index = 0, isComponentDidMount=true) {
    const {kindTitle, kindId} = this.props.location.state
    const curTag = this.state.tagsData[index]
    if (isComponentDidMount) {
      const wpsDwParams = {
        data1: `${kindTitle}_${kindId}`,
        data2: `${curTag.name}_${curTag.use_tag_id || curTag.industry_tag_id || curTag.style_tag_id || 0}`,
        position: 'categorypage'
      }
      wpsDw('button_click', 'categorypage_categorytab_click', wpsDwParams)
      wpsDw('page_show', 'categorypage_show', wpsDwParams)
      wpsDw('page_show', 'viptab_show', wpsDwParams)
    }
    this.props.getWpsDwParams && this.props.getWpsDwParams({data1: `${kindTitle}_${kindId}_${curTag.name}_${curTag.use_tag_id || curTag.industry_tag_id || curTag.style_tag_id || 0}`})
    nodeAlign(this.secondCon.current, index)
    scrollToTop(document.querySelector(`.${CKT_MAIN_CONTENT_CLASS}`))
    this.listQuery.page_no = 1
    this.setState({
      selectTagIndex: index,
      sort_type: 0,
      price_type: 2,
      showPriceOpt: false
    }, () => {
      this.getSecondTemps(true)
    })
    
  }
  // 切换显示价格排序
  togglePriceOpt() {
    this.setState((pre) => {
      return {
        showPriceOpt: !pre.showPriceOpt
      }
    })
  }
  // 设置当前排序类型
  setCurSort(v) {
    const dwMap = {
      0: 'hot',
      1: 'new',
      2: 'use',
      3: 'collect',
    }
    wpsDw('button_click', 'categorypage_selectway_click', {
      data3: dwMap[v.sort_type]
    })
    this.listQuery.page_no = 1
    this.setState({
      sort_type: v.sort_type
    }, () => {
      this.getSecondTemps(true)
    })
  }
  // 设置当前价格排序类型
  setCurPrice(v) {
    const dwMap = {
      2: 'all',
      0: 'free',
      1: 'docer'
    }
    wpsDw('button_click', 'categorypage_selectway_click', {
      data3: dwMap[v.price_type]
    })
    this.listQuery.page_no = 1
    this.setState({
      price_type: v.price_type,
    }, () => {
      this.getSecondTemps(true)
    })
  }
  // 获取标签下模板数据
  getSecondTemps(isClearTempList = false) {
    if (this.state.currentKind === -1) return
    const {sort_type, price_type, currentKind, tagsData, selectTagIndex} = this.state
    request.post('getWpsMobileAllTemplates', {
      sort_type,
      price_type,
      kind_id: currentKind,
      ...tagsData[selectTagIndex],
      ...this.listQuery
    }).then((res) => {
      const code = res.data.body.code
      if (code === 200) {
        const {templateList = [], totalCount = null} = res.data.body.data
        this.setState((pre) => {
          let tempList = pre.tempList
          if (isClearTempList) {
            // 判断是否需要清空templist
            tempList = templateList
          } else {
            tempList = tempList.concat(templateList)
          }
          let imgStyle = pre.imgStyle
          if (templateList[0]) {
            // 获取模板样式
            imgStyle = this.getImgStyle(templateList[0])
          }
          return { tempList, totalCount, imgStyle }
        })
      } else if (code === -2) {
        if (this.listQuery.page_no === 1) {
          this.setState({
            totalCount: 0,
            tempList: []
          })
        }
      }
    }).catch((err) => {
      console.log('err', err)
    })
  }
  // 获取当前模板的宽高度
  getImgStyle(temp) {
    if (!temp) return this.state.imgStyle
    const screenWidth = window.screen.width
    let {width, height} = temp
    width = parseInt(width)
    height = parseInt(height)
    if (width / height > 1.2) {

    }
    const newWidth = screenWidth * ( width / height > 1.2 ? .454 : .293 )
    return {
      'width': `${newWidth}px`,
      'height': `${newWidth / width * height}px`,
      'marginBottom': '.24rem'
    }
  }
  // 触底回调
  reachBottom() {
    this.listQuery.page_no ++
    this.getSecondTemps()
  }
  render() {
    const {tagsData, currentKind, selectTagIndex, sortOpts, showPriceOpt, priceOpts, sort_type, price_type, tempList, totalCount, imgStyle} = this.state
    return <div className="homeOrSecond-con" id={SCROLL_WRAP_ID}>
      <div className="homeOrSecond-top">
        <SearchBtn onClick={() => this.props.history.push({pathname: '/search', state: {pos: 'categorypagesearch'}})} />
        <div className='secondKind' ref={this.secondCon}>
          {tagsData.map((item, index) => {
            const {name} = item
            const secondKindItem = classnames('secondKind-item', {'active': selectTagIndex === index})
            return <div className={secondKindItem} onClick={this.toggleSecondKind.bind(this, index)} key={index}>{name}</div>
          })}
        </div>
      </div>
      <div className='more-options-con'>
        <div className='opt-con'>
          {sortOpts.map((item) => {
            const sortItem = classnames('sort-item', {'active': sort_type === item.sort_type})
            return <div onClick={this.setCurSort.bind(this, item)} className={sortItem} key={item.sort_type}>{item.title}</div>
          })}
          <div className={classnames('sort-item', 'show-price-btn', {'close': showPriceOpt})} onClick={this.togglePriceOpt.bind(this)}>{showPriceOpt ? '收起' : '展开'}</div>
        </div>
        {showPriceOpt && <div className='opt-con price-sort-con'>
          {priceOpts.map((item) => {
            const sortItem = classnames('sort-item', {'active': price_type === item.price_type})
            return <div onClick={this.setCurPrice.bind(this, item)} className={sortItem} key={item.price_type}>{item.title}</div>
          })}
        </div>}
      </div>
      <ListContainer className="temps-con" reachBottom={this.reachBottom.bind(this)}>
        {tempList.map((item) => {
          const curTag = tagsData[selectTagIndex]
          return <ItemElement
            wpsDw={{ name: 'categorypage_template_click', param: { 
              position: 'categorypage',
              data1: `${item.kindTitle}_${currentKind}`,
              data2: `${tagsData[selectTagIndex].name}_${curTag.use_tag_id || curTag.industry_tag_id || curTag.style_tag_id || 0}`,
              data3: `${item.templateTitle}_${item.designTemplateId}`,
            } }}
            key={item.designTemplateId}
            imgStyle={imgStyle}
            data={item} />
        })}
        {/* 两个i标签起到占位作用，防止最后一排元素不够三个导致垂直方向错位 */}
        <i style={{...imgStyle, height: 0, marginBottom: 0}}></i>
        <i style={{...imgStyle, height: 0, marginBottom: 0}}></i>
      </ListContainer>
      { (totalCount === tempList.length && totalCount > 0) && <BottomTips/> }
      { totalCount === 0 && <div className='no-result-con'>
        <img className='img' src={noResult} alt="" />
        <div className='no-text'>暂无模板</div>
      </div> }
    </div>
  }
}

export default SecondLabel