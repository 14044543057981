import {eventBus} from '../config/eventBus';
export const pxTransform=(numPx)=>{
  return numPx / 100 + 'rem'
}

export const compareVersion = (v1, v2) => {
  v1 = v1.split('.')
  v2 = v2.split('.')
  const len = Math.max(v1.length, v2.length)

  while (v1.length < len) {
    v1.push('0')
  }
  while (v2.length < len) {
    v2.push('0')
  }

  for (let i = 0; i < len; i++) {
    const num1 = parseInt(v1[i])
    const num2 = parseInt(v2[i])

    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  }

  return 0
}
export const easeOutScroll = (from, to, callback) => {
  if (from === to || typeof from !== 'number') {
    return
  }
  let change = to - from
  const dur = 500
  const sTime = +new Date()
  function linear(t, b, c, d) {
    return c * t / d + b
  }
  const isLarger = to >= from

  function step() {
    from = linear(+new Date() - sTime, from, change, dur)
    if ((isLarger && from >= to) || (!isLarger && to >= from)) {
      callback(to)
      return
    }
    callback(from)
    requestAnimationFrame(step)
  }
  step()
}

export const nodeAlign = (parentNode, index) => {
  const { offsetLeft: containerLeft, offsetWidth: containerWidth, scrollLeft: containerScrollLeft} = parentNode
  const { offsetLeft: curChildLeft, offsetWidth: curChildWidth } = parentNode.childNodes[index]
  let newScrollLeft = curChildLeft - containerLeft - (containerWidth - curChildWidth) / 2
  easeOutScroll(containerScrollLeft, newScrollLeft, (val) => {
    parentNode.scrollLeft = val
  })
}

export const judgePlatform = () => {
  const ua = window.navigator.userAgent.toLocaleLowerCase()
  if (/(iphone|ipad|ipod|ios)/i.test(ua)) {
    return 'ios'
  } else if (/(android)/i.test(ua)) {
    return 'android'
  } else {
    return 'pc'
  }
}

//判断设备是否是平板
export const isTablet = ()=>{
  const ua = navigator.userAgent;
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  const isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
  return isTablet
}

export const scrollToTop = (containerRef) => {
  easeOutScroll(containerRef.scrollTop, 0, (newVal) => {
    containerRef.scrollTop = newVal
  })
}

export const throttle = (fn, delay) => {
  let timer = null
  return function () {
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn()
    }, delay)
  }
}

/**
 * 使用后只有pageDom内的元素允许滚动
 * @param pageDom 被滚动的元素
 * @param panelDom 滚动页面的容器元素
*/
export const DomTouchMove = class DomTouchMove {
  constructor(pageDom, panelDom) {
    this.pageDom = pageDom
    this.panelDom = panelDom
    this.beforePageY = 0
  }
  // 用于解绑touchmove事件（防止页面切换时瘫痪）
  queitHandler(event) {
    event.preventDefault()
  }
  preventAppTouchMove() {
    document.querySelector('#root').addEventListener('touchmove', this.queitHandler, { passive: false })
  }
  recoverAppTouchMove() {
    document.querySelector('#root').removeEventListener('touchmove', this.queitHandler, { passive: false })
  }
  initDomTouchMove() {
    this.preventAppTouchMove()

    this.pageDom.addEventListener('touchstart', (event) => {
      this.beforePageY = event.changedTouches[0].pageY
    })

    // Allows content to move on touch.
    this.pageDom.addEventListener('touchmove', (event) => {
      if (this.pageDom.clientHeight <= this.panelDom.clientHeight) return
      const panelHeight = this.panelDom.clientHeight // 容器可视区高
      const innerScrollHeight = this.panelDom.scrollHeight // 滚动元素高
      const innerScrollTop = this.panelDom.scrollTop // 滚动元素卷曲距离
      const isReachBottom = (innerScrollTop + panelHeight) === innerScrollHeight // 是否触底(根据dom属性可以先行判断出是否处于触底位置))
      const isMoveDown = event.changedTouches[0].pageY - this.beforePageY > 0
      const isMoveX = event.changedTouches[0].pageY - this.beforePageY === 0 // 水平方向滑动
      this.beforePageY = event.changedTouches[0].pageY

      if (isMoveX) { // 水平任意滑动
        event.stopPropagation()
      }
      if (innerScrollTop > 0 && !isReachBottom) { // 未触顶且未触底
        event.stopPropagation()
      }
      if (isReachBottom && isMoveDown) { // 触底时只能下滑
        event.stopPropagation()
      }
      if (innerScrollTop <= 0 && !isMoveDown) { // 触顶时只能上滑
        event.stopPropagation()
      }
    }, false)
  }
}

export const getQueryVal = (queryName) => {
  const query = decodeURI(window.location.search.substring(1));
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (pair[0] === queryName) { return pair[1]; }
  }
  return null;
}

export const wpsDw = (evName = '', typeName = '', param2 = {}) => {
  const nameObj = {}
  if (evName === 'page_show') {
    nameObj['page_name'] = typeName
  } else {
    nameObj['button_name'] = typeName
  }
  let comp, {isWps, isSearchPage, wpsUserId, wpsViptabPosition} = eventBus.state
  if (isWps) {
    comp = isSearchPage ? 'search' : 'mall'
  } else {
    comp = wpsViptabPosition
  }
  const wpsParams = {
    comp,
    'func_name': 'mob_docer_chuangkit',
    '_account_id': wpsUserId,
    ...nameObj,
    ...param2,
    position: param2.position ? wpsViptabPosition + '_' + param2.position : wpsViptabPosition
  }
  // wps埋点
  try {
    if(window.navigator.userAgent.toLocaleLowerCase().indexOf('wps') > -1) {
      window.wps.dataCollect(evName, {...wpsParams})
    } else {
      window._dw.onEvent(evName, {...wpsParams})
    }
    console.log('埋点参数', evName, wpsParams);
  } catch (error) {
    console.log(error, '埋点出错', evName);
  }
}

export function getBaseParams(){
  const from = getQueryVal('from')
  let product_name = 3
  if(from === 'specialwps'){
    product_name = 5
  }

  const ua = window.navigator.userAgent.toLocaleLowerCase()
  let platform = 3
  if(ua.includes('micromessenger')){
    platform = 14
    if(ua.includes('android')){
      platform = 15
    }
  }else if(ua.includes('android')){
    platform = 2
  }
  return { product_name, platform }
}