import React from 'react'
import classnames from 'classnames';

import wpsContainer from '../../../components/wpsContainer/index';
import SearchBtn from '../../../components/searchBtn/index';
import Recommend from './components/Recommend'
import RubberStop from "../../../components/rubberStop/index";
import { SCROLL_WRAP_ID, CKT_MAIN_CONTENT_CLASS } from "../../../config/constant";

import navIcon from '../../../assets/images/pub/index-nav-icon.png';

import request from '../../../config/request';

import './index.less'
import { wpsDw } from '../../../utils/tools';
@wpsContainer({name: 'hometab_show'}, {vipBar: 'viptab_click', vipParam: {position: 'hometab'}})
@RubberStop(`#${SCROLL_WRAP_ID}`, `.${CKT_MAIN_CONTENT_CLASS}`)
class Home extends React.Component {
  state = {
    currentKind: -1, // 当前二级分类id
    secondKinds: [], // 所有二级分类
  }
  pointTest = React.createRef()
  componentDidMount() {
    this.getTopSecondKind()
    this.wpsDWReqort()
  }
  // wps埋点数据处理
  wpsDWReqort() {
    let startY, movewFlag = false
    document.addEventListener('touchstart', (ev) => {
      startY = ev.touches[0].pageY; 
    })
    this.pointTest.current.addEventListener("touchend", (ev) => {
      if (!movewFlag) {
        movewFlag = true
        if (ev.changedTouches[0].pageY < startY) {
          wpsDw('button_click', 'hometab_pagedown')
        }
      }
    })
  }
  // 获取顶部二级分类数据
  getTopSecondKind() {
    request.get('getDesignKindBasicInfo', {type: 2}).then((res) => {
      if (res.data.body.code === 200) {
        this.getWeChatFavoriteIds(res.data.body.data.secondLabel) // 筛选出用户的收藏场景
      }
    })
  }
  // 获取用户收藏的场景id
  getWeChatFavoriteIds(mobileThemes) {
    request.get('getWeChatFavoriteIds').then((res) => {
      const code = res.data.body.code
      if (code === 200) { // 已登录
        mobileThemes = this.filterThemeById(res.data.body.data.split(','), mobileThemes)
      }
      mobileThemes.unshift({ kindId: -1, kindTitle: '推荐'})
      this.setState({
        secondKinds: mobileThemes,
      })
    })
  }
  // 筛选出收藏的二级分类
  filterThemeById(myThemeIds, mobileThemes) {
    const mobileThemesmap = {}
    mobileThemes.forEach((item) => {
      mobileThemesmap[item.kindId] = item
    })
    const myThemes = []
    myThemeIds.forEach((item) => {
      if (mobileThemesmap[item]) {
        myThemes.push(mobileThemesmap[item])
      }
    })
    return myThemes
  }
  // 切换顶部二级分类数据
  toggleSecondKind(kindId, kindTitle) {
    
    if (kindId === -1) {
      wpsDw('button_click', 'hometab_categorytab_click', {data1: 'reference'})
    } else {
      wpsDw('button_click', 'hometab_categorytab_click', {data1: `${kindTitle}_${kindId}`})
      this.props.history.push({ pathname: `/secondLabel`, state: { kindId, kindTitle }})
    }
  }
  render() {
    const {secondKinds, currentKind} = this.state
    return <div id={SCROLL_WRAP_ID} ref={this.pointTest}>
      <div className="homeOrSecond-top">
        <SearchBtn onClick={() => {
          wpsDw('button_click', 'hometab_searchbar_click')
          this.props.history.push({pathname: '/search', state: {pos: 'hometabsearch'}})
        }} />
        <div className='secondKind-con'>
          <div className='secondKind'>
            {secondKinds.map(item => {
              const {kindId, kindTitle} = item
              const secondKindItem = classnames('secondKind-item', {'active': currentKind === kindId})
              return <div className={secondKindItem} onClick={this.toggleSecondKind.bind(this, kindId, kindTitle)} key={kindId}>{kindTitle}</div>
            })}
          </div>
          <div className='diy-btn' onClick={() => this.props.history.push('/diySort')}><img src={navIcon} alt=""/></div>
        </div>
      </div>
      {currentKind === -1 && <Recommend />}
    </div>
  }
}
export default Home