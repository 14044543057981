
import { DomTouchMove } from "../../utils/tools";

/**
* @param pageSelector 被滚动的元素选择器
* @param panelSelector 滚动页面的容器元素选择器
*/
export default function RubberStop(pageSelector, panelSelector) {
  return (WrappedComponent) => {
    return class WrapperComponent extends WrappedComponent {
      componentDidMount() {
        super.componentDidMount && super.componentDidMount()
        this.iosRubber = new DomTouchMove(document.querySelector(pageSelector), document.querySelector(panelSelector))
        this.iosRubber.initDomTouchMove()
      }
      componentWillUnmount() {
        this.iosRubber.recoverAppTouchMove()
        this.iosRubber = null
      }
    }
  }
}
