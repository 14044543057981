import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './index.less'

class Toast extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  }
  static showToast = (props) => {
    const div = document.createElement('div');
    div.className = 'ckt-toast'
    const { dur } = props
    document.body.appendChild(div);
    ReactDOM.render(<Toast {...props} />, div);
    setTimeout(() => {
      ReactDOM.unmountComponentAtNode(div);
      div.parentNode.removeChild(div);
    }, dur || 2000)
  }
  render() {
    const {title} = this.props
    return <div className='toast-con'>
      {title}
    </div>
  }
}

export default Toast