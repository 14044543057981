/* eslint-disable react/jsx-indent-props */
import React from 'react'
import PropTypes from 'prop-types'
import freeIcon from "../../assets/images/pub/free_icon.png"
import daoziImg from "../../assets/images/wps/daoKeLogo-white.png"
import HandIcon from '../../assets/images/pub/hand.png';
import Image from '../image/index';

import {wpsDw, pxTransform, getQueryVal} from '../../utils/tools';
import {eventBus} from '../../config/eventBus';

import './index.less'
import request from '../../config/request';
import {process} from 'ckt_oss'
import async from 'resolve';

class ItemElement extends React.Component {
  static propTypes = {
    imgStyle: PropTypes.object, // 图片样式 若非瀑布流请统一计算好传入
    onItemTap: PropTypes.func, // 模板被点击时做出的事件响应
    defaultImg: PropTypes.string, // 默认图片，当图片地址为空或者图片无法显示的时候使用的
    data: PropTypes.object, // 图片数据
    imgKey: PropTypes.string, //数据中对应图片的属性名
    qualityWidth: PropTypes.number,//图片质量
    containerStyle: PropTypes.object,
    wpsDw: PropTypes.object,
    showGuide: PropTypes.bool
  }
  static defaultProps = {
    data: {},
    imgStyle: {
      width: 140,
      height: 'auto',
    },
    imgKey: 'designTemplateThumbUrls', //数据中对应图片的属性名
    qualityWidth: 400,//图片质量
    onItemTap: (e) => {

    },
    wpsDw: {
      name: '',
      param: {}
    },
    showGuide: false
  }

  constructor(props) {
    super(props)
    this.state = {
      imgUrl: '',
      isCollectModalShow: false,
      isload: false,
      showGuide: props.showGuide,
      isNewWps: !!eventBus.state.loginCode,
      isWechat: eventBus.state.isWechat
    }
    const { imgUrl, imageStyle } = this.getImgState()
    this.state.imgUrl = imgUrl
    this.state.imageStyle = imageStyle

    this.itemEle = React.createRef() 
    this.handTouch = this.handTouch.bind(this)
  }
  componentDidMount() {
    // 长按事件
    this.itemEle.current.addEventListener("touchstart", this.handTouch)
    this.itemEle.current.addEventListener("touchmove", this.handTouch)
    this.itemEle.current.addEventListener("touchend", this.handTouch)
    this.props.onRef && this.props.onRef(this)
  }
  componentWillUnmount() {
    this.itemEle.current.removeEventListener("touchstart", this.handTouch)
    this.itemEle.current.removeEventListener("touchmove", this.handTouch)
    this.itemEle.current.removeEventListener("touchend", this.handTouch)
  }
  getImgState() {
    let imgUrl = ''
    if (Object.prototype.toString.call(this.props.data[this.props.imgKey]) === '[object Array]') {
      imgUrl = this.props.data[this.props.imgKey][0] || ''
    }
    if (Object.prototype.toString.call(this.props.data[this.props.imgKey]) === '[object String]') {
      imgUrl = this.props.data[this.props.imgKey] || ''
    }
    const v = (this.props.data.thumbnailsUpdateTime && this.props.data.thumbnailsUpdateTime.time) || Date.now()
    // imgUrl = `https:${imgUrl}${imgUrl.includes('?') ? '&' : '?'}v=${v}&x-oss-process=image/resize,w_${this.props.qualityWidth}/format,jpg`
    imgUrl = process(`https:${imgUrl}`, `v=${v}&x-oss-process=image/resize,w_${this.props.qualityWidth}/format,jpg`)
    
    return { imgUrl }
  }
  loadError() {
    this.setState({
      imgUrl: this.props.defaultImg
    })
  }
  async createDesignTemplate(designTemplateId, price) {
    const res = await React.axios.get('getDesignOpenSwitch', {design_id: designTemplateId} )
    const { code, data } = res.data.body // -101参数错误;-102未登录;-103无模板记录;200成功
    if(code === 200){
      if(!data.flag){
        React.showModal({
          title: '提示',
          content:  data.paperwork || '',
          showCancel: false,
          confirmText: '我知道了',
        })
        return
      }
    }
    const {name = '', param = {}} = this.props.wpsDw
    wpsDw('button_click', name, {...param, 'data4': price === 0 ? 'free' : 'docer'})
    const {searchKey = 'none'} = this.props.wpsDw.param
    const {kindTitle, designKindId, templateTitle} = this.props.data
    let wpsDwQuery = `&kt=${encodeURIComponent(kindTitle)}&ktd=${designKindId}&td=${encodeURIComponent(templateTitle)}&wf=${encodeURIComponent(eventBus.state.wpsViptabPosition)}&way=mb`
    if (eventBus.state.isSearchPage) {
      wpsDwQuery += `&isSearch=1&searchkw=${encodeURIComponent(searchKey)}`
    }
    if(getQueryVal('entry_params')){
      // let entryParams =JSON.parse(decodeURIComponent(getQueryVal('entry_params')))
      // if(window.sessionStorage.getItem('shareFrom') && entryParams.first_entry == 'null'){
      //   entryParams.first_entry = window.sessionStorage.getItem('shareFrom')
      //   wpsDwQuery = `${wpsDwQuery}&entry_params=${JSON.stringify(entryParams)}`
      // }else{
        wpsDwQuery = `${wpsDwQuery}&entry_params=${getQueryVal('entry_params')}`
      // }
    }

    if(getQueryVal('entry_to')){
      wpsDwQuery = `${wpsDwQuery}&entry_to=${getQueryVal('entry_to')}`
    }
    if(getQueryVal('state')){
      wpsDwQuery = `${wpsDwQuery}&state=${getQueryVal('state')}`
    }
    if(getQueryVal('position')){
      wpsDwQuery = `${wpsDwQuery}&position=${getQueryVal('position')}`
    }
    if(getQueryVal('from')){
      wpsDwQuery = `${wpsDwQuery}&from=${getQueryVal('from')}`
    }
    if (window.GBCKT.isLogined) {
      window.location.href = `${window.location.origin}/mcreatedesign?t=${designTemplateId}&cktFrom=1${wpsDwQuery}&startTime=${new Date().getTime()}`
    } else {
      if (eventBus.state.isWechat) {
        if (eventBus.state.isWechatMin) {
          React.wx.miniProgram.navigateTo({
            url : '/pages/openWin/openWin?url=' + window.encodeURIComponent(`${window.location.origin}/mcreatedesign?t=${designTemplateId}&cktFrom=1${wpsDwQuery}&startTime=${new Date().getTime()}`)
          })
        } else {
          let params = ''
          if(getQueryVal('entry_params')){
            // let entryParams =JSON.parse(decodeURIComponent(getQueryVal('entry_params')))
            // if(window.sessionStorage.getItem('shareFrom') && entryParams.first_entry == 'null'){
            //   entryParams.first_entry = window.sessionStorage.getItem('shareFrom')
            //   params = `&entry_params=${JSON.stringify(entryParams)}`
            // }else{
              params = `&entry_params=${getQueryVal('entry_params')}`
            // }
          }

          if(getQueryVal('state')){
            params = `${params}&state=${getQueryVal('state')}`
          }
          if(getQueryVal('entry_to')){
            params = `${params}&entry_to=${getQueryVal('entry_to')}`
          }
          if(getQueryVal('position')){
            params = `${params}&position=${getQueryVal('position')}`
          }
          if(getQueryVal('from')){
            params = `${params}&from=${getQueryVal('from')}`
          }
          request.login(`${window.location.origin}/mcreatedesign?t=${designTemplateId}${params}&startTime=${new Date().getTime()}`)
        }
      } else {
        request.login().then(res => {
          if (res.code === 200) {
            window.location.href = `${window.location.origin}/mcreatedesign?t=${designTemplateId}&cktFrom=1${wpsDwQuery}&startTime=${new Date().getTime()}`
          }
        })
      }
      
    }
  }
  handTouch(e) {
    const eventType = e.type
    switch (eventType) {
      case 'touchstart':
        this.touchStartTime = Date.now()
        this.timer = setTimeout(() => {
          if (window.location.href.includes('/collection')) return
          const {wpsPrice, wpsMobilePrice} = this.props.data
          let price
          if (wpsPrice !== undefined) {
            price = wpsPrice
          }
          if (wpsMobilePrice !== undefined) {
            price = wpsMobilePrice
          }
          const wpsParams = {
            position: (this.props.wpsDw.param.position || ''),
            data3: this.props.wpsDw.param.data3, 'data4': price === 0 ? 'free' : 'docer'
          }
          if (this.props.wpsDw.param.searchKey) {
            wpsParams.data2 = this.props.wpsDw.param.searchKey
          }
          wpsDw('page_show', 'previewpage_show', wpsParams)
          React.showPreModal({
            ...this.props.data,
            smImgUrl: this.state.imgUrl,
            wpsDw: wpsParams
          })
          // 关闭预览弹框引导
          this.setState({
            showGuide: false
          })
          window.localStorage.setItem('hasGuided', false)
        }, 800)
        break;
      case 'touchmove':
        clearTimeout(this.timer)
        break;
      case 'touchend':
        if (Date.now() - this.touchStartTime < 1000) {
          clearTimeout(this.timer)
        }
        break;
      default:
        break;
    }
  }
  render() {
    const { imgUrl, isload, showGuide, isNewWps, isWechat } = this.state
    const { data: { designTemplateId, useTimes, wpsPrice, wpsMobilePrice}, imgStyle } = this.props
    let price
    if (wpsPrice !== undefined) {
      price = wpsPrice
    }
    if (wpsMobilePrice !== undefined) {
      price = wpsMobilePrice
    }
    const notShowFeeIcon = isNewWps || isWechat
    return (<>
      <div
        className='item-image-wrap'
        style={imgStyle}
        ref={this.itemEle}
        onClick={this.createDesignTemplate.bind(this, designTemplateId, price)}>
        {showGuide && <div className='hand-mask' style={{"width": imgStyle.width, "height": imgStyle.height}}>
          <div className='mask-con'>
            <div><img src={HandIcon} alt='' /></div>
            <span className='hand-desc'>—<span style={{padding: `0 ${pxTransform(20)}`}}>长按预览</span>—</span>
          </div>
        </div>}
        {
          (!notShowFeeIcon) && <>
            {(price && price > 0) ? (
              <div className='vipTip'>
                <img src={daoziImg} className='daozi' alt='稻壳' />
                <span className='text'>稻壳会员免费</span>
              </div>
            ) : (
              <img src={freeIcon} className='freeIcon' alt='免费' />
            )}
          </>
        }
        <Image src={imgUrl} lazyLoad load={() => {this.setState({isload: true})}} className='item-image' onError={this.loadError.bind(this)} alt='模板' />
        {useTimes && isload? <div className='useTimes'>{useTimes}人使用</div>:''}
      </div>
    </>)
  }
}
export default ItemElement