
import React from 'react'
import ItemElement from '../../../../../components/itemElement/index.jsx'
import { pxTransform } from '../../../../../utils/tools'
import './index.less'

const ThemeContent = (props) => {
  const { extstyle: { width, height }, templates} = props
  const imgStyle = { width: pxTransform(width), height: pxTransform(height), margin: pxTransform(5) }
  return (
    <div className='contentBlock'>
      {templates && templates.map((templateItem) => (
        <ItemElement
          wpsDw={{name: 'topicstab_template_click', param: {
            position: 'topicstab',
            data3: `${templateItem.templateTitle}_${templateItem.designTemplateId}`
          }}}
          key={templateItem.designTemplateId}
          // onClick={this.tempClick.bind(this, templateItem)}
          data={templateItem}
          imgStyle={imgStyle}
        />
      ))}
    </div>
  )
}
export default ThemeContent