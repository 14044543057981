
import React from 'react'
import './index.less'
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom"

import icon1 from '../images/icon1.png'
import icon2 from '../images/icon2.png'
import icon3 from '../images/icon3.png'
import icon4 from '../images/icon4.png'
import icon5 from '../images/icon5.png'
import icon6 from '../images/icon6.png'
import icon7 from '../images/icon7.png'
import icon8 from '../images/icon8.png'
const iconConfig = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8]

const KeywordBlock = (props) => {
  const { keywordList } = props
  return <>
    <div className='keywordsBlock'>
      {keywordList.map((kewordItem,keywordIndex) => (
        <div className='keywordItem' key={kewordItem.id} onClick={() => {
          props.history.push({ pathname: '/search', state: {kw: kewordItem.keyword} })
        }}>
          <img src={iconConfig[keywordIndex]} alt="" />
          <span>{kewordItem.keyword}</span>
        </div>
      ))}
    </div>
    <div className='divide'></div>
  </>
}
KeywordBlock.propTypes = {
  keywordList: PropTypes.array,
}
KeywordBlock.defaultProps = {
  keywordList: []
}
export default withRouter(KeywordBlock)
