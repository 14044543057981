import React from 'react'
import PropTypes from 'prop-types';
import './index.less'

import KeywordBlock from './KeywordBlock'
import ThemeScroll from './ThemeScroll'
import ThemeBlockItem from './ThemeBlockItem'
import KindBlockItem from './KindBlockItem'
import ListContainer from '../../../../components/listContainer/index';
import BottomTips from '../../../../components/bottomTips'
import BannerBlock from './Banner/index'
import Loading from '../../../../components/loading/index'

class Home extends React.Component {
  static propTypes = {
    keywordList: PropTypes.array,
    themeScroll: PropTypes.array,
    themeBlockList: PropTypes.array,
    kindBlockList: PropTypes.array,
  }
  static defaultProps = {
    keywordList: [],
    themeScroll: [],
    themeBlockList: [],
    kindBlockList: [],
  }
  constructor(props) {
    super(props)
    this.state = {
      bannerList: [], // banner
      keywordList: [], // 搜索关键词
      themeScroll: [], // 热门专题
      themeBlockList: [], // 专题展示
      kindBlockList: [], // 场景展示
      isBottomShow: false, // 无更多数据
      isGuideShow: !Boolean(window.localStorage.getItem('hasGuided'))
    }
    this.currentBlockIndex = 0
    this.themeIndex = 0 // 专题模块的索引
    this.kindIndex = 0 // 场景模块的索引
    this.currentBlock = 0 // 当前触底加载专题还是场景: 1专题 2场景
    this.guideBlock = 0 // 收藏引导显示的位置: 4专题 5场景
    this.themePageNo = 0 // 专题页码
    this.totalThemeCount = 0 // 专题总数
    this.kindPageNo = 0 // 场景页码
    this.moduleSortList = [] // 模块请求顺序
    this.reachBottom = this.reachBottom.bind(this)
  }
  componentDidMount() {
    this.loadModuleSort().then((moduleSortList) => {
      this.moduleSortList = moduleSortList
      for (let i = moduleSortList.length - 1; i >= 0; i--) {
        if (moduleSortList[i].state===1) { // 1是隐藏
          moduleSortList.splice(i, 1)
        }
      }
      for (let i = 0; i < moduleSortList.length; i++) {
        // 加载对应模块
        switch (moduleSortList[i].id) {
          // 1.banner 2关键词，3专题列表，4专题模板，5推荐场景
          case 1:
            this.loadBannerBlock()
            break;
          case 2:
            this.loadKeywordBlock()
            break;
          case 3:
            this.loadThemeBlock()
            break;
          case 4:
            this.loadThemeTempBlock()
            break;
          case 5:
            this.loadKindTempBlock()
            break;
          default:
            break;
        }

        if (moduleSortList[i].id === 4 || moduleSortList[i].id === 5) {
          this.guideBlock = moduleSortList[i].id
          break
        }
        this.currentBlockIndex = i
      }
    })
  }
  reachBottom() {
    if (this.state.isBottomShow ) return
    let currentBlockId 
    try {
      currentBlockId = this.moduleSortList[this.currentBlockIndex].id
    } catch (error) {
      currentBlockId = -1
    }
    if (currentBlockId === 4) { 
      if (this.state.themeBlockList.length >= this.totalThemeCount) {
        this.loadNextBlock()
      } else {
        this.loadThemeTempBlock()
      }
    } else if (currentBlockId === 5) {
      this.loadKindTempBlock()
    } else if (this.currentBlockIndex <= this.moduleSortList.length - 1) {
      this.loadNextBlock()
    }
  }
  loadNextBlock() {
    ++this.currentBlockIndex
    if (this.currentBlockIndex > this.moduleSortList.length - 1) {
      this.setState({
        isBottomShow: true
      })
      return
    }
    // 加载对应模块
    try {
      switch (this.moduleSortList[this.currentBlockIndex].id) {
        // 1.banner 2关键词，3专题列表，4专题模板，5推荐场景
        case 1:
          this.loadBannerBlock()
          break;
        case 2:
          this.loadKeywordBlock()
          break;
        case 3:
          this.loadThemeBlock()
          break;
        case 4:
          this.loadThemeTempBlock()
          break;
        case 5:
          this.loadKindTempBlock()
          break;
        default:
          break;
      }
    } catch (error) {
      this.setState({
        isBottomShow: true
      })
      console.error(error, 'maybe have noMore module');
    }
  
  }
  // 加载banner模块
  async loadBannerBlock() { 
    const res = await React.axios.post('getWeChatHomeBanner')
    const {code} = res.data.body
    if (code === 200) {
      const { data: bannerList } = res.data.body
      this.setState({ bannerList })
    }
  }
  // 加载关键词模块
  async loadKeywordBlock() {
    try {
      const res = await React.axios.post('getWpsMobileKeyword')
      const { code } = res.data.body
      if (code === 200) {
        const { data: keywordList } = res.data.body
        if (keywordList.length>8) {
          keywordList.splice(8)
        }
        this.setState({ keywordList })
      }
      if (this.currentBlockIndex === this.moduleSortList.length-1) {
        this.setState({ isBottomShow: true })
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 加载专题列表模块
  async loadThemeBlock() {
    try {
      const res = await React.axios.post('getWpsMobileTheme')
      const { code } = res.data.body
      // -101:没有专题；200:成功
      if (code === 200) {
        const { data: themeScroll } = res.data.body
        this.setState({ themeScroll })
      }
      if (this.currentBlockIndex === this.moduleSortList.length-1) {
        this.setState({ isBottomShow: true })
      }
    } catch (error) {
      console.log(error);
    }
  }
  // 加载专题模板模块
  async loadThemeTempBlock() {
    try {
      const res = await React.axios.post('getWpsMobileThemeTemplate', { page_no: ++this.themePageNo })
      const { code } = res.data.body
      // -101:没有专题；200:成功
      if (code === 200) {
        const { data: { themes: themeBlockList, totalThemeCount } } = res.data.body
        this.setState({
          themeBlockList: [...this.state.themeBlockList, ...themeBlockList]
        })
        this.totalThemeCount = totalThemeCount
      }
      if (code === -101) {
        if (this.currentBlockIndex === this.moduleSortList.length-1) {
          this.setState({ isBottomShow: true })
        }
        this.loadNextBlock()
      }
    } catch (error) {
      console.log(error);
      this.loadNextBlock()
    }
  }
  // 加载推荐场景模块
  async loadKindTempBlock() {
    try {
      const res = await React.axios.post('getWpsMobileRecommendKindTemplate', { page_no: ++this.kindPageNo })
      const { code, data: kindBlockList } = res.data.body
      // -102:没有；200:成功
      if (code === 200) {
        this.setState({
          kindBlockList: [...this.state.kindBlockList, ...kindBlockList]
        })
      }
      if (code === -102) {
        if (this.currentBlockIndex === this.moduleSortList.length-1) {
          this.setState({ isBottomShow: true })
        }
        this.loadNextBlock()
      }
    } catch (error) {
      console.log(error);
      this.loadNextBlock()
    }
  }
  // 获取模块顺序
  loadModuleSort() {
    return new Promise(async (resolve) => {
      const res = await React.axios.get('getWpsMobileModuleList')
      const { code, data: moduleSortList } = res.data.body
      if (code === 200) {
        resolve(moduleSortList)
      }
    })
  }
  render() {
    const { bannerList, keywordList, themeScroll, themeBlockList, kindBlockList, isBottomShow, isGuideShow } = this.state
    // 2关键词，3专题列表，4专题模板，5推荐场景
    return (
      <ListContainer className='recommendPage'  reachBottom={this.reachBottom}>
        {this.moduleSortList.map((item, index) => {
          if (item.state === 1) return <div key={index}></div>
          if (item.id === 1) {
            return bannerList.length > 0 ? <BannerBlock key={index} bannerList={bannerList} /> : ('')
          }
          if (item.id === 2) {
            return keywordList.length > 0 ? <KeywordBlock key={index} keywordList={keywordList} /> : ('')
          }
          if (item.id === 3) {
            return themeScroll.length > 0 ? <ThemeScroll key={index} themeScroll={themeScroll} /> : ('')
          }
          if (item.id === 4) {
            return (
              <div key={index}>
                {themeBlockList.map((themeBlockItem, themeBlockIndex) => {
                  if (themeBlockItem.templates.length > 0) {
                    return <ThemeBlockItem key={themeBlockIndex} themeBlockItem={themeBlockItem} isGuideShow={this.guideBlock === 4 && themeBlockIndex === 0 && isGuideShow} />
                  } else {
                    return <></>
                  }
              })}
              </div>
            )
          }
          if (item.id === 5) {
            return (
              <div key={index}>
                {kindBlockList.map((kindBlockItem, kindBlockIndex) => (
                  <KindBlockItem key={kindBlockIndex} kindBlockItem={kindBlockItem} isGuideShow={this.guideBlock === 5 && kindBlockIndex === 0 && isGuideShow} />
                ))}
              </div>
            )
          }
          return <div key={index}></div>
        })}
        {!isBottomShow ? <Loading /> : ('')}
        {isBottomShow ? <BottomTips /> : ('') }
      </ListContainer>
    )
  }
}
export default Home