const computeWaterfall = ({ eleData, listSum, boxRect}) => {
    // 每列高度数组
    let listHeight = new Array(listSum)
    for (let i = 0; i < listSum; i++) {
      listHeight[i] = 0
    }
    // 元素宽、高、比例
    let eleDataWidth, eleDataHeight
    // 图片容器宽高、定位
    const boxWidth = boxRect.width + boxRect.left + boxRect.right
    let boxHeight, boxTop, boxLeft
    // 当前最短列数据
    let min
    // 图片容器内图片高度
    let imgHeight
    let waterFallList = []
    // 瀑布流高度
    let waterFallHeight 
  
    if (!eleData) return
  
    for (let i = 0; i < eleData.length; i++) {
      eleDataWidth = parseFloat(
        eleData[i].width ||
        eleData[i].design_width ||
        eleData[i].oldWidth ||
        eleData[i].w ||
        eleData[i].designWidth
      )
      eleDataHeight = parseFloat(
        eleData[i].height ||
        eleData[i].design_height ||
        eleData[i].oldHeight ||
        eleData[i].h ||
        eleData[i].designHeight
      )
      //正常比例 固定宽/图片宽=需要缩小的比例*高=缩小后的高
      imgHeight = (boxRect.width / eleDataWidth) * eleDataHeight
      boxHeight = imgHeight + boxRect.top + boxRect.bottom
      min = getMin(listHeight)
      boxTop = min.top===0?0:min.top + boxRect.marginTop
      boxLeft = (boxWidth + boxRect.marginLeft) * min.num //横坐标只能是 *0或*1
      // 重置listHeight
      listHeight[min.num] = boxTop + boxHeight
  
      waterFallList[i] = {
        ...eleData[i],
        boxStyle: {
          itemWidth: boxWidth + 'px',
          imgHeight: boxHeight + 'px',
          left: boxLeft + 'px',
          top: boxTop + 'px',
        },
        imgStyle: {
          width: boxWidth + 'px',
          height: imgHeight + 'px'
        },
      }
    }
    waterFallHeight = getMax(listHeight)
    return { waterFallList, waterFallHeight }
  }
  
  // 获取最低列
  const getMin = (arr) => {
    let min = arr[0]
    let j = 0 //j为列的坐标
    for (let i = 0; i < arr.length; i++) {
      if (min > arr[i]) {
        min = arr[i]
        j = i
      }
    }
    return {
      top: min,
      num: j
    }
  }
  
  // 获取最高列
  const getMax = (arr) => {
    let max = arr[0]
    for (let i = 0; i < arr.length; i++) {
      if (max < arr[i]) {
        max = arr[i]
      }
    }
    return max + 8 + 'px'
  }
  
  export default computeWaterfall;