import React from 'react'
import ThemeItem from './components/themeItem/themeItem'
import BottomTips from '../../../components/bottomTips'
import Loading from '../../../components/loading'
import wpsContainer from '../../../components/wpsContainer/index';
import ListContainer from '../../../components/listContainer/index';
import RubberStop from "../../../components/rubberStop/index";
import { SCROLL_WRAP_ID, CKT_MAIN_CONTENT_CLASS } from "../../../config/constant";
import './index.less'

@wpsContainer({ name: 'topicstab_show' }, {vipBar: 'viptab_click', vipParam: {position: 'topicstab'}})
@RubberStop(`#${SCROLL_WRAP_ID}`, `.${CKT_MAIN_CONTENT_CLASS}`)
class ThemePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      themeList: [], // 主题列表
      isBottomShow: false, // 无更多数据
    }
    this.pageNo = 0
    this.isLoading = false

    this.gotoMore = this.gotoMore.bind(this)
    this.reachBottom = this.reachBottom.bind(this)
  }
  componentDidMount() {
    this.getThemeData()
  }
  async getThemeData() {
    if (this.isLoading) return
    if (this.state.isBottomShow) return
    this.isLoading = true
    const res = await React.axios.post('getWpsMobileFeaturedTopics', { page_no: ++this.pageNo })
    this.isLoading = false
    const { code } = res.data.body
    if (code === 200) {
      const data = res.data.body.data
      if (!data || !data.themes || data.themes.length === 0) { // 空数据处理
        this.setState({ isBottomShow: true })
        return
      }
      const themeList = data.themes

      themeList.forEach(themeItem => { // 设置宽高
        const templates = themeItem.templates
        if (templates.length === 0) return // 空数据处理
        const { width, height } = templates[0]
        const ratio = parseInt(width) / parseInt(height)
        themeItem.isWideImg = ratio > 1.2
        themeItem.extstyle = {
          width: themeItem.isWideImg ? 340 : 220,
          height: themeItem.isWideImg ? 340 / ratio : 220 / ratio
        }
      })

      Array.prototype.push.apply(this.state.themeList, themeList)
      this.setState({ themeList: this.state.themeList })
    }
  }
  reachBottom(isVisible) {
    if (this.state.isBottomShow) return
    this.getThemeData()
  }
  gotoMore(id, title) {
    this.props.history.push({ pathname: `/themeDetail`, state: { id, title } })
  }
  renderThemeBlockList() {
    const { themeList } = this.state
    return (
      <ListContainer reachBottom={this.reachBottom}>
        {themeList.map((themeItem, themeIndex) => (
          <ThemeItem key={themeItem.id} data={themeItem} onGoMore={this.gotoMore} />
        ))}
      </ListContainer>
    )
  }
  render() {
    const { themeList, isBottomShow } = this.state
    return (
      <div className='themeWrap' id={SCROLL_WRAP_ID}>
        {this.renderThemeBlockList()}
        {(themeList.length >= 3 && !isBottomShow) ? (<Loading />) : ('')}
        {(themeList.length >= 3 && isBottomShow) ? (<BottomTips />) : ('')}
      </div>
    )
  }
}
export default ThemePage

