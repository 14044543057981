import React from 'react';
import ReactDOM from 'react-dom';
import './index.less'

class Loading extends React.Component {
  static start = () => {
    const div = document.createElement('div');
    div.className = 'ckt-loading'
    document.body.appendChild(div);
    ReactDOM.render(<Loading />, div);
  }
  static done = () => {
    const div = document.querySelector('.ckt-loading')
    ReactDOM.unmountComponentAtNode(div);
    div.parentNode.removeChild(div);
  }
  render() {
    return <div className="quanquan"></div>
  }
}

export default Loading