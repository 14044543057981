/* eslint-disable react/jsx-indent-props */
import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../../components/image/index';

import '../../../components/itemElement/index.less'
import './materialItem.less'

class MaterialItem extends React.Component {

  static propTypes = {
    imgStyle: PropTypes.object, // 图片样式 若非瀑布流请统一计算好传入
    defaultImg: PropTypes.string, // 默认图片，当图片地址为空或者图片无法显示的时候使用的
    data: PropTypes.object, // 图片数据
    // imgKey: PropTypes.string, //数据中对应图片的属性名
    // qualityWidth: PropTypes.number,//图片质量
  }
  static defaultProps = {
    data: {},
    imgStyle: {
      width: 140,
      height: 'auto',
    },
  }

  constructor(props) {
    super(props)
    this.state = {
      imgUrl: `https:${this.props.data.imgurl}`,
      isload: false
    }
  }
  loadError() {
    this.setState({
      imgUrl: this.props.defaultImg
    })
  }
  render() {
    console.log(this.props);
    const { imgUrl } = this.state
    const { imgStyle, data: { isSmall } } = this.props
    return (<>
      <div
        className='item-image-wrap material-image-wrap'
        style={imgStyle}>
        <Image src={imgUrl} lazyLoad load={() => { this.setState({ isload: true }) }} style={{ height: isSmall?'auto':'100%' }} className='item-image' onError={this.loadError.bind(this)} alt='' />
      </div>
    </>)
  }
}
export default MaterialItem