import React from 'react'
import waterFall from '../../utils/waterFall'
import { pxTransform, scrollToTop } from '../../utils/tools'
import classnames from 'classnames';

import DeleteIcon from '../../assets/images/pub/delete.png';
import RubbishIcon from './images/rubbish.png';
import CloseIcon from './images/close.png'

import './index.less'

import wpsContainer from '../../components/wpsContainer/index';
import MaterialItem from './components/materialItem'
import ListContainer from '../../components/listContainer/index';
import BottomTips from '../../components/bottomTips'
import EmptyTips from './components/emptyTips'
import Loading from '../../components/loading'

import { MATERIAL_PAGE_CLASS } from '../../config/constant'

@wpsContainer()
class Material extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      materialList: [], // 素材列表
      isBottomShow: false, // 尾页
      isInDelect: false, // 是否处于删除状态
      isDeleteBtnSHow: false, // 是否显示底部删除按钮
      isEmptyShow: false, // 是否展示空页面提示
      totalSize: 0, // 总容量
      usedSize: 0, // 已用容量 
      isToTopShow: true, // 在需要时隐藏
    }
    this.pageParams = {
      w: 300,
      pageNo: 0,
      pageSize: 10,
      fid: -1,
      // fid: 539647,
    }
    this.deleteIds = new Set() // 批量删除id
    this.totalCount = 0 // 收藏模板总数量
    this.waterFall = {}  // 瀑布流对象
    this.isLoading = false

    this.materialPageEle = React.createRef()

    this.reachBottom = this.reachBottom.bind(this)
    this.toggoleDeleteState = this.toggoleDeleteState.bind(this)
    this.deleteTemp = this.deleteTemp.bind(this)
  }
  componentDidMount() {
    this.initWaterFall()
    this.reloadTemplateList()
    this.loadUserCapacity()
  }
  // 初始化瀑布流
  initWaterFall() {
    const lL = 24, rL = 385, outerHeight = 20, itemWidth = 341
    this.waterFall = new waterFall([], lL, rL, outerHeight, itemWidth)
  }
  reachBottom() {
    if (this.state.isBottomShow) return
    if (this.isLoading) return
    this.loadMaterial()
  }
  async loadUserCapacity() {
    const res = await React.axios.post('getUserCapacity', { wps_user_flag: 1 })
    const { code } = res.data.body
    if (code === 200) {
      const { data: { totalSize, usedSize } } = res.data.body
      this.setState({
        totalSize,
        usedSize
      })
    }
    if (code === -2) {
      React.axios.login()
    }
  }
  // 获取收藏列表
  async loadMaterial() {
    this.isLoading = true
    this.pageParams.pageNo++
    const res = await React.axios.post('getMaterialsInFolder', this.pageParams)
    this.isLoading = false
    const {code} = res.data.body
    // 操作的错误码(-1参数有误；-2用户未登录；-3权限不足)
    if (code) {
      let msg = ''
      switch (code) {
        case -2:
          React.axios.login()
          break
        case -1:
          msg = '参数错误'
          break
        case -3:
          msg = '权限不足'
          break
        default:
          console.error(`getMaterialsInFolder result: ${msg}, code: ${code}`)
          break;
      }
      return
    }  
    
    const { pageTotal, recordData: materialList, recordCount } = res.data.body.page
    materialList.forEach((item) => {
      const originWidth = item.w
      const originHeight = item.h
      if (originWidth > originHeight) {
        item.width = 340
        item.height = 340
        item.isSmall = true
      } else {
        item.width = item.w
        item.height = item.h
      }
    })
    this.pageTotal = pageTotal
    this.waterFall.add(materialList)
    const stateObj = {}
    if (this.pageParams.pageNo >= this.pageTotal) { // 尾页
      stateObj.isBottomShow = true
    }
    if (recordCount === 0) { // 无素材
      stateObj.isEmptyShow = true
    }
    stateObj.materialList = this.waterFall.waterFallList
    stateObj.materialH = this.waterFall.waterFallHeight
    this.setState(stateObj)
  }
  // 进入和取消删除操作
  toggoleDeleteState() {
    const { materialList, isInDelect } = this.state
    materialList.forEach(item => {
      item.isInDel = false
    })
    this.deleteIds.clear()
    this.setState({
      isInDelect: !isInDelect,
      isDeleteBtnSHow: false,
      isToTopShow: isInDelect
    })
  }
  // 批量删除收藏模板
  async deleteTemp() {
    const params = {
      m_ids: [...this.deleteIds].join(','),
      fid: 0,
    }
    const res = await React.axios.post('delMaterials', params)
    const { code } = res.data.body
    // 操作的状态码（-3参数错误；-2未登录；-4权限不足；0操作失败；1操作成功）
    switch (code) {
      case 1:
        this.deleteIds.clear()
        this.reloadTemplateList()
        this.setState({
          isInDelect: false,
          isDeleteBtnSHow: false,
          isBottomShow: false
        })
        scrollToTop(this.materialPageEle.current)
        this.loadUserCapacity()
        break;
      case 0:
        console.log('操作失败');
        break;
      case -2:
        React.axios.login()
        break;
      case -3:
        console.log('参数错误');
        break;
      case -4:
        console.log('权限不足');
        break;
      default:
        break;
    }
  }
  // 重新加载第一页
  reloadTemplateList() {
    this.pageParams.pageNo = 0
    this.waterFall.clear() // 清空瀑布流
    this.loadMaterial()
  }
  // 更新正在操作的删除id列表
  updateDeleteList(index) {
    const { materialList } = this.state
    const materialItem = materialList[index]
    const prevItemState = materialItem.isInDel
    if (this.deleteIds.size >= 20 && !prevItemState) { 
      React.showToast({title: '单次操作最多可选20个'})
      return
    }

    const stateObj = {}
    materialItem.isInDel = !prevItemState
    if (materialItem.isInDel) {
      this.deleteIds.add(materialItem.mid)
    } else {
      this.deleteIds.delete(materialItem.mid)
    }
    if (this.deleteIds.size>0) {
      stateObj.isDeleteBtnSHow = true
    } else {
      stateObj.isDeleteBtnSHow = false
    }
    stateObj.materialList = this.state.materialList
    this.setState(stateObj)
  }
  // 渲染模板
  renderMaterial() {
    const { materialList, materialH, isInDelect } = this.state
    return (
      <div className='templateWrap' style={{ 'height': pxTransform(materialH) }}>
        {materialList.map((materialItem, tempalteIndex) => {
          const imgConStyle = {
            top: pxTransform(materialItem.top),
            left: pxTransform(materialItem.left),
            height: pxTransform(materialItem.imgHeight),
          }
          const deleteClass = classnames({
            deleteMask: true,
            active: materialItem.isInDel
          })
          return (
            <div className='templateItem' style={imgConStyle} key={materialItem.mid}>
              {isInDelect ? (
                <div
                  className={deleteClass}
                  style={{ width: pxTransform(341), height: pxTransform(materialItem.imgHeight) }}
                  onClick={this.updateDeleteList.bind(this, tempalteIndex)}
                >
                </div>
              ) : ('')}
              <MaterialItem
                data={materialItem}
                imgStyle={{ width: pxTransform(341), height: pxTransform(materialItem.imgHeight), margin: pxTransform(5) }}
              />
            </div>
          )
        })}
      </div>
    )
  }
  renderHead() {
    const { isInDelect } = this.state
    return (
      <div className='materialHeadBlock'>
        <div className='navTop'>
          <div className='deleteBtn' onClick={this.toggoleDeleteState}>
            {isInDelect ? (
              <img className='closeIcon' src={CloseIcon} alt='' /> 
            ) : (
              <><img className='rubbishIcon' src={RubbishIcon} alt='' /> 删除</>
            )}
          </div>
        </div>
      </div>
    )
  }
  // 渲染删除按钮
  renderDeleteBtn() {
    return (
      <div onClick={this.deleteTemp} className="collectDeleteBtn">
        <img className='delete-icon' src={DeleteIcon} alt="" />
        <span>删除</span>
      </div>
    )
  }
  // 渲染容量
  rendderCapacity() {
    const { totalSize, usedSize } = this.state
    const widhtPercent = parseFloat(usedSize / totalSize) * 100 + '%'
    if (window.GBCKT.isLogined) {
      return (
        <div className='capacity'>
          <div className='totalSize'>
            <div className='usedSize' style={{ width: widhtPercent }}></div>
          </div>
          <div className='tips'>{usedSize}M / {totalSize}M</div>
        </div>
      )
    } else {
      return ''
    }
   
  }
  render() {
    const { materialList, isBottomShow, isDeleteBtnSHow, isEmptyShow, isToTopShow } = this.state
    return (
      <div className='materialPageBottomWrap'>
        <div className={MATERIAL_PAGE_CLASS} ref={this.materialPageEle}>
          {this.renderHead()}
          <ListContainer reachBottom={this.reachBottom} isToTopShow={isToTopShow}>
            {this.renderMaterial()}
          </ListContainer>
          {(materialList.length >= 10 && !isBottomShow) ? <Loading /> : ('')}
          {(materialList.length >= 10 && isBottomShow) ? <BottomTips /> : ('')}
          {isEmptyShow ? <EmptyTips /> :('')}
        </div>
        {isDeleteBtnSHow ? this.renderDeleteBtn() : ('')}
        {this.rendderCapacity()}
      </div>
    )
  }
}

export default Material