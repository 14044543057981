import React from 'react';
import '../index.less'

class BannerDots extends React.Component {
  render () {
    return (
      <div className='dots'>
        {this.getEmptyArr(this.props.len).map((item, index)=>(
          <div className={index === this.props.currentIndex ? 'dot active' : 'dot'} key={index}></div>
        ))}
      </div>
    )
  }
  getEmptyArr (len){
    let tempArr = []
    for (let i=0; i<len; i++){
      tempArr[i] = []
    }
    return tempArr
  }
}

export default BannerDots 
