/* eslint-disable react/jsx-indent-props */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import LoveIcon from '../../../assets/images/pub/love.png';
import DeepLoveIcon from '../../../assets/images/pub/deeplove.png';
import closeBtn from '../../../assets/images/pub/close.png';

import {wpsDw} from '../../../utils/tools';
import request from '../../../config/request';

import './index.less';

import {eventBus} from '../../../config/eventBus';

export default class CollectModal extends React.Component {
  static propTypes = {
    smImgUrl: PropTypes.string, // 低质量图片
    designTemplateId: PropTypes.number, // 模板id
    width: PropTypes.string, // 原图片宽
    height: PropTypes.string, // 原图片高
  }
  static modalQueue = []
  static showPreModal = (props) => {
    CollectModal.modalQueue.push(props)
    const currentProps = CollectModal.modalQueue.shift()
    if (CollectModal.modalQueue.length>0) return
    let modalDiv = CollectModal.modalDiv
    if (!modalDiv) {
      modalDiv = document.createElement('div')
      modalDiv.className = 'ckt-collectModal'
      document.body.appendChild(modalDiv)
      CollectModal.modalDiv = modalDiv
    }
    ReactDOM.render(<CollectModal {...currentProps} />, modalDiv);
  }
  constructor(props) {
    super(props)
    this.state = {
      smImgUrl: this.props.smImgUrl,
      hdImgUrl: '',
      isLove: false,
      imgStyle: {}
    }
    this.mask = React.createRef()
    this.setImgWH.call(this)
    this.canIclose = false 
    this.el = document.createElement('div')
    
    this.renderContent = this.renderContent.bind(this)
    this.removeSelf = this.removeSelf.bind(this)
    this.changeLoveState = this.changeLoveState.bind(this)
    this.handTouch = this.handTouch.bind(this)
  }

  componentDidMount() {
    console.log(this.props);
    this.judeLoveState()
    this.setHDImg()
    document.body.appendChild(this.el)
    this.mask.current.addEventListener("touchmove", this.handTouch)
    this.mask.current.addEventListener("touchstart", this.handTouch)
  }

  componentWillUnmount() {
    this.mask.current.removeEventListener("touchmove", this.handTouch)
    this.mask.current.removeEventListener("touchstart", this.handTouch)
    document.body.removeChild(this.el)
  }
  handTouch(e) { // ios触摸穿透bug
    const eventType = e.type
    switch (eventType) {
      case 'touchmove':
        e.preventDefault()
        e.stopPropagation()
        break;
      case 'touchstart':
        break;
      default:
        break;
    }
    this.canIclose = true
  }
  setImgWH() {
    const { width, height } = this.props
    const whRatio = parseFloat(width) / parseFloat(height)
    const designedWidth = 750
    const pxRatio = parseFloat(window.innerWidth / designedWidth)
    const bottomMinHeight = pxRatio * 240
    const imgWrapMaxWidth = (designedWidth - 92 * 2)*pxRatio
    const imgWrapMaxHeight = window.innerHeight - bottomMinHeight - 96*pxRatio
    let imgH, imgW
    if (imgWrapMaxWidth / whRatio > imgWrapMaxHeight) {
      imgH = imgWrapMaxHeight
      imgW = imgWrapMaxHeight * whRatio
    } else {
      imgW = imgWrapMaxWidth
      imgH = imgWrapMaxWidth / whRatio
    }
    
    this.state.imgStyle = { // eslint-disable-line
      width: imgW,
        height: imgH,
    }
  }
  removeSelf(e) {
    wpsDw('button_click', 'previewpage_click', { data1: 'close', ...this.props.wpsDw})
    if (this.canIclose) {
      ReactDOM.unmountComponentAtNode(CollectModal.modalDiv);
    }
  }
  // 判断收藏状态
  judeLoveState() {
    const { likeSet } = window.GBCKT
    const { designTemplateId } = this.props
    if (likeSet.has(designTemplateId)) {
      this.setState({ 
        isLove: true
      })
    }
  }
  // 设置高清图
  setHDImg() {
    let imgUrl = this.props.smImgUrl.replace(/resize,w_\d+/, 'resize,w_800')
    const HDImg = new Image();
    HDImg.src = imgUrl
    HDImg.onload = () => { 
      this.setState({
        hdImgUrl:imgUrl
      })
    } 
  }
  // 改变收藏状态
  async changeLoveState(e) {
    e.stopPropagation()
    wpsDw('button_click', 'previewpage_click', { data1: 'favor', ...this.props.wpsDw})
    // 1. 未登录：去登录
    const { isLogined } = window.GBCKT
    
    if (!isLogined) { 
      React.axios.login()
      return
    }

    // 2. 已登录：改变收藏收藏列表、视图状态、数据
    const { isLove } = this.state
    const { designTemplateId } = this.props
    const url = isLove ? 'unCollect' : 'collectTemplate'
    const params = isLove ? { ids: designTemplateId } : { id: designTemplateId }
    const res = await React.axios.post(url, params)
    const { code } = res.data.body
    if (code === -2) { // 登录状态失效
      React.axios.login()
    } else if (code === 1) {
      const { likeSet } = window.GBCKT
      if (isLove) {
        likeSet.delete(designTemplateId)
      } else {
        likeSet.add(designTemplateId)
      }
      this.setState({ isLove: !isLove })
    } else { 
      this.removeSelf()
      React.showModal({
        title: '提示',
        content: '操作频繁请稍候重试',
      })
    }
  }
  createDesign() {
    const { kindTitle, designKindId, templateTitle, designTemplateId} = this.props
    wpsDw('button_click', 'previewpage_click', { data1: 'edit', ...this.props.wpsDw})
    const {searchKey} = this.props.wpsDw
    let wpsDwQuery = `&kt=${kindTitle}&ktd=${designKindId}&td=${templateTitle}&wf=${eventBus.state.wpsViptabPosition}&way=prev`
    if (eventBus.state.isSearchPage) {
      wpsDwQuery += `&isSearch=1&searchkw=${searchKey}`
    }
    console.log(`${window.location.origin}/mcreatedesign?t=${designTemplateId}&cktFrom=1${wpsDwQuery}&startTime=${new Date().getTime()}`);
    if (window.GBCKT.isLogined) {
      window.location.href = `${window.location.origin}/mcreatedesign?t=${designTemplateId}&cktFrom=1${wpsDwQuery}&startTime=${new Date().getTime()}`
    } else {
      request.login().then(res => {
        if (res.code === 200) {
          window.location.href = `${window.location.origin}/mcreatedesign?t=${designTemplateId}&cktFrom=1${wpsDwQuery}&startTime=${new Date().getTime()}`
        }
      })
    }
  }
  renderContent() {
    const { isLove, hdImgUrl, smImgUrl, imgStyle } = this.state
    return (
      <div className='portalMask' onClick={this.removeSelf} ref={this.mask}>
        <div className='imgWrap'>
          <div style={imgStyle} className="img-item">
            <img className='temp' src={hdImgUrl ? hdImgUrl:smImgUrl} alt="" style={imgStyle} onClick={this.createDesign.bind(this)} />
            <img className='close-btn' src={closeBtn} alt="" onClick={this.removeSelf}/>
          </div>
          <div className='edit-tip animated fadeOut' onClick={this.createDesign.bind(this)}>轻触图片进入编辑</div>
        </div>
        <div className='tipsWrap'>
          <img src={isLove?DeepLoveIcon:LoveIcon} alt="" onClick={this.changeLoveState}/>
          <span>{isLove ?'取消收藏':'收藏模板'}</span>
        </div>
      </div>
    )
  }
  render() {
    return ReactDOM.createPortal(
      this.renderContent(),
      this.el,
    )
  }
}
