import React from 'react'
import classnames from 'classnames';

import wpsContainer from '../../components/wpsContainer/index';
import ListContainer from '../../components/listContainer/index';
import ItemElement from '../../components/itemElement/index';
import BottomTips from '../../components/bottomTips/index';
import RubberStop from "../../components/rubberStop/index";

import request from '../../config/request';
import WaterFall from '../../utils/waterFall';
import { nodeAlign, getQueryVal, wpsDw } from '../../utils/tools';
import { SCROLL_WRAP_ID, CKT_MAIN_CONTENT_CLASS } from "../../config/constant";


import './index.less'
import searchIcon from '../../assets/images/pub/index-search-icon.png';
import noResult from '../../assets/images/pub/no-design.png'

@wpsContainer({}, {vipBar: 'viptab_click', vipParam: {position: 'searchpage'}})
@RubberStop(`#${SCROLL_WRAP_ID}`, `.${CKT_MAIN_CONTENT_CLASS}`)
class Search extends React.Component {
  state = {
    hotKeywords: [], // 热门搜索关键词
    historyKeywords: [], // 历史搜索关键词
    secondLabel: [], // 移动端二级分类
    isSearchKw: false, // 是否通过关键词搜索
    inputKeyword: '', // 输入框关键词
    time_order: '', // 时间排序
    curSecondKindId: '', // 当前二级分类id
    totalCount: -1, // 搜索结果数量
    templates: [], // 搜索结果列表
    waterFallHeight: 0, // 搜索结果列表高度
  }
  listQuery = {
    pageNo: 1,
    pageSize: 20
  }
  secondlabelCon = React.createRef() // 二级分类容器ref
  input = React.createRef() // 输入框ref
  waterFall = {}
  componentDidMount() {
    this.initWaterFall()
    let keyWord = getQueryVal('kw') || this.state.inputKeyword
    let kindId = (~~getQueryVal('id')) || this.state.curSecondKindId
    if (keyWord) {
      keyWord = decodeURIComponent(keyWord)
      this.setState({
        isSearchKw: true,
        inputKeyword: keyWord,
        curSecondKindId: kindId
      }, this.searchTemps)
    }
    this.props.getWpsDwParams && this.props.getWpsDwParams({data4: keyWord || 'none'})
    this.getAllCategories()
    if (this.props.location.state && this.props.location.state.kw) {
      this.setState({
        isSearchKw: true,
        inputKeyword: this.props.location.state.kw,
        templates: []
      }, this.searchTemps)
      this.props.getWpsDwParams && this.props.getWpsDwParams({data4: this.props.location.state.kw})
    } else {
      this.getKeywords()
    }
    wpsDw('page_show', 'searchpage_show', { data1: `全部_0` })
  }
  // 初始化瀑布流
  initWaterFall() {
    const screenWidth = window.screen.width
    const lL = 24 / 750 * screenWidth
    const itemWidth = 341 / 750 * screenWidth
    const rL = screenWidth - lL - itemWidth
    const outerHeight = 21 / 750 * screenWidth
    this.waterFall = new WaterFall([], lL, rL, outerHeight, itemWidth)
  }
  // 获取搜索关键词
  getKeywords() {
    // 获取热门搜索关键词
    request.get('getRecommendKeyword', {from: 1}).then((res) => {
      if (res.data.body.code === 200) {
        this.setState({
          hotKeywords: res.data.body.data.slice(0, 6)
        })
      }
    })
    // 获取历史搜索关键词
    this.setState({
      historyKeywords: (JSON.parse(window.localStorage.getItem('newHistory')) || []).slice(0, 6)
    })
  }
  // 获取全部分类
  getAllCategories() {
    request.get('getDesignKindBasicInfo', {type: 2}).then((res) => {
      if (res.data.body.code === 200) {
        const secondLabel = res.data.body.data.secondLabel
        secondLabel.unshift({
          kindTitle: '全部',
          kindId: ''
        })
        this.setState({
          secondLabel
        })
      }
    })
  }
  // 搜索输入框提交
  searchInputSubmit(ev) {
    ev.preventDefault()
    this.waterFall.clear()
    this.listQuery.pageNo = 1
    this.setHistoryWords(this.input.current.value)
    this.setState({
      isSearchKw: true,
      inputKeyword: this.input.current.value,
      templates: []
    }, this.searchTemps)
    wpsDw('page_show', 'viptab_show', {position: 'searchpage', data4: this.input.current.value})
    this.props.getWpsDwParams && this.props.getWpsDwParams({data4: this.input.current.value})
  }
  // 模板搜索
  searchTemps() {
    const {curSecondKindId, inputKeyword, time_order} = this.state
    this.props.history.replace(`/search?kw=${inputKeyword}&id=${curSecondKindId}`)
    request.get('wxSmallAppSearch', {
      second_kind_id: curSecondKindId,
      kw: inputKeyword,
      time_order,
      ...this.listQuery
    }).then((res) => {
      if (res.data.body.code === 200) {
        const {templates = [], totalCount} = res.data.body
        const {waterFallHeight, waterFallList} = this.waterFall.add(templates)
        this.setState({
          templates: waterFallList,
          waterFallHeight
        })
        if (this.listQuery.pageNo === 1) {
          this.setState({ totalCount })
        }
      }
    })
  }
  //设置历史搜索
  setHistoryWords(word) {
    if (word === undefined || word === '') return
    const historyKeywords = this.state.historyKeywords
    const index = historyKeywords.indexOf(word)
    const KEYWORD_LEN = 6 // 历史纪录条数的最大值
    if (index > -1) {
      historyKeywords.splice(index, 1)
    }
    historyKeywords.unshift(word)
    if (historyKeywords.length > KEYWORD_LEN) {
      historyKeywords.pop()
    }
    window.localStorage.setItem('newHistory', JSON.stringify(historyKeywords))
    this.setState({
      historyKeywords
    })
  }
  // 点击关键词进行搜索
  clickKeywordSearch(word, form) {
    wpsDw('button_click', 'searchpage_keyword_click', {
      position: form,
      data4: word || 'none'
    })
    this.setHistoryWords(word)
    this.waterFall.clear()
    this.listQuery.pageNo = 1
    this.setState({
      isSearchKw: true,
      inputKeyword: word,
      templates: []
    }, this.searchTemps)
    wpsDw('page_show', 'viptab_show', {position: 'searchpage', data4: word})
    this.props.getWpsDwParams && this.props.getWpsDwParams({data4: word})
  }
  // 设置当前的二级分类id
  setCurrentKindId(index) {
    const { kindId, kindTitle } = this.state.secondLabel[index]
    wpsDw('button_click', 'searchpage_category_click', { data1: `${kindTitle}_${kindId}`, data4: this.state.inputKeyword})
    wpsDw('page_show', 'searchpage_show', { data1: `${kindTitle}_${kindId}` })
    nodeAlign(this.secondlabelCon.current, index)
    this.waterFall.clear()
    this.listQuery.pageNo = 1
    this.setState({
      curSecondKindId: this.state.secondLabel[index].kindId,
      templates: []
    }, this.searchTemps)
  }
  // 根据模板上新时间搜索
  setSortSearch(time_order) {
    wpsDw('button_click', 'searchpage_selectway_click', {
      data3: time_order === 1 ? 'new' : 'hot',
      data4: this.state.inputKeyword
    })
    this.waterFall.clear()
    this.listQuery.pageNo = 1
    this.setState({
      time_order,
      templates: []
    }, this.searchTemps)
  }
  // 触底回调
  reachBottom() {
    this.listQuery.pageNo ++
    this.searchTemps()
  }
  inputChange = (ev) => {
    this.props.getWpsDwParams && this.props.getWpsDwParams({data4: ev.target.value})
    this.setState({inputKeyword: ev.target.value})
  }
  render() {
    const { hotKeywords, historyKeywords, inputKeyword, secondLabel, isSearchKw, curSecondKindId, templates, waterFallHeight, totalCount, time_order } = this.state
    const showBottom = (templates.length === totalCount) && (totalCount > 10)
    return <div className='search-con' id={SCROLL_WRAP_ID}>
      {/* 搜索框 */}
      <div className='search-top-con'>
        <form onSubmit={(ev) => {this.searchInputSubmit(ev)}} className='input-con'>
          <img src={searchIcon} alt=""/>
          <input className='search-input' value={inputKeyword} onChange={(ev)=>{this.inputChange(ev)}} ref={this.input} type="text" placeholder='30000+ 免费模板任你搜索'/>
        </form>
        <div className='cancle-btn' onClick={() => this.props.history.goBack()}>取消</div>
      </div>
      {
        isSearchKw
        ?
        <div className='more-search-opt'>
          <div className='second-label-con' ref={this.secondlabelCon}>
            {secondLabel.map((item, index) => (
              <div onClick={this.setCurrentKindId.bind(this, index)} className={classnames('second-label-item', {'active': curSecondKindId === item.kindId})} key={item.kindId}>{item.kindTitle}</div>
            ))}
          </div>
          {
            totalCount > 0 && <div className="sort-con">
              <p className='temp-count'>{totalCount}个模板</p>
              <div className='sort-item'>
                <span className={classnames({active: time_order === ''})} onClick={this.setSortSearch.bind(this, '')}>最热</span>
                <span style={{margin: '0 .2rem'}}>|</span>
                <span className={classnames({active: time_order === 1})} onClick={this.setSortSearch.bind(this, 1)}>最新</span>
              </div>
            </div>
          }
        </div>
        :
        <div className='keyword-con'>
          {
            hotKeywords.length > 0 && <div className='hot-keyword-con'>
              <p className="keyword-title">热门搜索</p>
              {hotKeywords.map((item) => (
                <div onClick={this.clickKeywordSearch.bind(this, item.keyword, 'hotsearch')} className='keyword-item' key={item.keyword}>{item.keyword}</div>
              ))}
            </div>
          }
          {
            historyKeywords.length > 0 && <div className='hot-keyword-con'>
              <p className="keyword-title">历史搜索</p>
              {historyKeywords.map((item) => (
                <div onClick={this.clickKeywordSearch.bind(this, item, 'historysearch')} className='keyword-item' key={item}>{item}</div>
              ))}
            </div>
          }
        </div>
      }
      {/* 搜索结果列表 */}
      <ListContainer reachBottom={this.reachBottom.bind(this)}>
        <div style={{'height': `${waterFallHeight}px`}} className='search-list-con'>
          {templates.map((item) => {
            const {designTemplateId, itemWidth, imgHeight, left, top} = item
            const imgStyle = {
              width: itemWidth + 'px',
              height: imgHeight + 'px',
              top: top + 'px',
              left: left + 'px',
              position: 'absolute'
            }
            return <ItemElement wpsDw={{
              name: 'searchpage_template_click',
              param: {
                position: 'searchpage',
                searchKey: inputKeyword,
                data1: `${item.kindTitle}_${item.designKindId}`,
                data2: inputKeyword || 'none',
                data3: `${item.templateTitle}_${item.designTemplateId}`,
              }
            }} className="search-item" key={designTemplateId} imgStyle={imgStyle} data={item} />
          })}
        </div>
        { showBottom && <BottomTips />}
      </ListContainer>
      {
        totalCount === 0 && <div className='no-result-con'>
          <img className='img' src={noResult} alt='' />
          <div className='no-text'>没有找到你搜索的内容</div>
          <div className='change-text'>换个词试试</div>
        </div>
      }
    </div>
  }
}

export default Search