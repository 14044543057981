import React from 'react';
import PropTypes from 'prop-types';
import ToTop from '../toTop/index';

import { CKT_MAIN_CONTENT_CLASS } from "../../config/constant";

class ListContainer extends React.Component{
  static propTypes = {
    reachBottom: PropTypes.func,
    rootMargin: PropTypes.number,
    className: PropTypes.string,
    isToTopShow: PropTypes.bool,
  }
  static defaultProps = {
    reachBottom: () => {},
    rootMargin: 200,
    className: '',
    isToTopShow: true
  }
  state = {
    showToTop: false
  }
  observer = null
  isFirst = true
  bottomRef = React.createRef() // 触底的标志
  notFirstScreen = React.createRef() // 超过首屏的标志
  componentDidMount() {
    const options = {
      root: document.querySelector('#root'),
      rootMargin: `${this.props.rootMargin}px 0px`,
      threshold: [1]
    }
    const handler = (entries) => {
      if (this.isFirst) {
        this.isFirst = false
        return // 解决默认就是相交状态导致回调执行的bug
      }
      const curEntry = entries[0];
      const refType = curEntry.target.dataset.type
      if (curEntry.isIntersecting) {
        if (refType === 'bottomRef') {
          this.props.reachBottom()
        } else if (refType === 'notFirstScreen') {
          this.setState({
            showToTop: true
          })
        }
      } else {
        if (refType === 'notFirstScreen') {
          if (curEntry.boundingClientRect.top < 0) return
          this.setState({
            showToTop: false
          })
        }
      }
    }
    this.observer = new IntersectionObserver(handler, options)
    this.observer.observe(this.bottomRef.current);
    this.observer.observe(this.notFirstScreen.current);
    this.setState({
      containerRef: document.querySelector(`.${CKT_MAIN_CONTENT_CLASS}`)
    })
  }
  componentWillUnmount() {
    this.observer.disconnect()
  }
  render() {
    const { className, isToTopShow } = this.props
    const commonStyle = {
      position: 'absolute',
      left: '0px',
      height: '10px',
      opacity: 0,
      width: '100%',
    }
    const notFirstScreenStyle = {
      top: window.screen.height + 'px',
      zIndex: 99999,
      background: 'red'
    }
    const {containerRef, showToTop} = this.state
    return <div style={{'position': 'relative', 'overflow': 'hidden'}}>
      <div className={className}> {this.props.children} </div>
      <div style={{...commonStyle, ...notFirstScreenStyle}} data-type="notFirstScreen" ref={this.notFirstScreen}></div>
      { (isToTopShow&&showToTop) && <ToTop containerRef={containerRef} /> }
      <div style={{...commonStyle, 'bottom': '10px'}} data-type="bottomRef" ref={this.bottomRef}></div>
    </div>
  }
}

export default ListContainer