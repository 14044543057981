import React from 'react';
import Banner from './Banner'
import Dots from './BannerDots'
import '../index.less'

class BannerDots extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0 // 当前索引
    }
  }
  render() {
    const { bannerList } = this.props
    return (
      <div className='swiperBlock'>
        <Banner banner={bannerList} onChangeDot={this.changeDot.bind(this)} />
        {bannerList.length > 1 ? <Dots len={bannerList.length} currentIndex={this.state.currentIndex} /> : ''}
      </div>
    )
  }
  changeDot(currentIndex) {
    this.setState({currentIndex})
  }
}

export default BannerDots 
