import React from 'react'
import wpsContainer from '../../../components/wpsContainer/index';
import login from '../../../components/login/index';
import './index.less'
import { eventBus } from '../../../config/eventBus';
import RubberStop from "../../../components/rubberStop/index";
import { SCROLL_WRAP_ID, CKT_MAIN_CONTENT_CLASS } from "../../../config/constant";
@login
@wpsContainer({ name: 'minetab_show' }, {vipBar: 'viptab_click', vipParam: {position: 'minetab'}})
@RubberStop(`#${SCROLL_WRAP_ID}`, `.${CKT_MAIN_CONTENT_CLASS}`)
class UserCenter extends React.Component {
  state = {
    userInfo: eventBus.state.userInfo,
    wpsUserInfo: eventBus.state.wpsUserInfo
  }
  locationTo = this.locationTo.bind(this)
  locationTo(path) {
    this.props.history.push(path)
  }
  setUserInfo = (userInfo) => {
    this.setState({
      userInfo
    })
  }
  componentDidMount() {
    eventBus.on('userInfo', this.setUserInfo)
  }
  componentWillUnmount() {
    eventBus.off('userInfo', this.setUserInfo)
  }
  render() {
    let nickname, picUrl
    let { wpsUserInfo, userInfo } = this.state
    const { isWps } = eventBus.state
    if (isWps) {
      nickname = wpsUserInfo.nickname || ''
      picUrl = wpsUserInfo.picUrl || ''
    } else {
      nickname = decodeURIComponent(userInfo.nickname)
      let userHeadImgUrl = userInfo.userHeadImgUrl
      if (userHeadImgUrl && userHeadImgUrl.indexOf('img/user_head.png') > -1) {
        userHeadImgUrl = 'https://www.chuangkit.com/img/user_head.png'
      } else {
        userHeadImgUrl = `https:${userHeadImgUrl}`
      }
      picUrl = userHeadImgUrl
    }
    return <div className="userCenter-con" id={SCROLL_WRAP_ID}>
      <div className='userCenter'>
        <img className="left" src={picUrl} alt=""/>
        <div className='right'>
          <p className='nickname'>{nickname}</p>
          {/* <div className="vip-text">个人VIP</div> */}
        </div>
      </div>
      <ul className="coloum-con">
        <li onClick={this.locationTo.bind(this, '/myDesign')}>我的设计</li>
        <li onClick={this.locationTo.bind(this, '/collection')}>我的收藏</li>
        <li onClick={this.locationTo.bind(this, '/material')}>我的图库</li>
        <li onClick={this.locationTo.bind(this, '/contact')}>联系客服</li>
      </ul>
    </div>
  }
}
export default UserCenter