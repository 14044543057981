
import React from 'react'
import starIcon from '../../../../../assets/images/pub/star.png'
import './index.less'
import { wpsDw } from '../../../../../utils/tools'

const ThemeHead = (props) => {
  return (
    <div className='headBlock'>
      <img className='starIcon' src={starIcon} alt='star' />
      <div className='titleBlock'>
        <span className='maintitle'>{props.title}</span>
        <span className='subtitle'>{props.subTitle}</span>
      </div>
      <div className='moreBtn' onClick={() => {
        wpsDw('button_click', 'topicstab_moretemplate_click', {
          data2: `${props.title}_${props.id}`
        })
        props.onGoMore(props.id, props.title) 
      }}>更多</div>
    </div>
  )
}
export default ThemeHead
