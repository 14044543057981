import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './index.less'

class Modal extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    showCancel: PropTypes.bool,
    cancelText: PropTypes.string,
    cancelColor: PropTypes.string,
    confirmText: PropTypes.string,
    confirmColor: PropTypes.string,
    success: PropTypes.func,
    calcel: PropTypes.func,
    // fail: PropTypes.func,
    // complete: PropTypes.func,
  }
  static defaultProps = {
    showCancel: false,
    cancelText: "取消",
    cancelColor: "#000000",
    confirmText: "确定",
    confirmColor: "#0773fc",
  }
  static showModal = (props) => {
    let modalDiv = Modal.modalDiv
    if (!modalDiv) {
      modalDiv = document.createElement('div')
      modalDiv.className = 'ckt-modal'
      document.body.appendChild(modalDiv)
      Modal.modalDiv = modalDiv
    }
    ReactDOM.render(<Modal {...props} />, modalDiv);

  }
  confirm() {
    this.props.success && this.props.success()
    this.closeModal()
  }
  cancel() {
    this.props.calcel && this.props.calcel()
    this.closeModal()
  }
  closeModal() {
    ReactDOM.unmountComponentAtNode(Modal.modalDiv);
  }
  render() {
    const { title, content, showCancel, cancelText, cancelColor, confirmText, confirmColor } = this.props
    return (
      <div className='modalMask'>
        <div className='modalWrap'>
          <p className='title'>{title}</p>
          <p className='content'>{content}</p>
          <div className='btnGroup'>
            {showCancel ? <div className='cancel' style={{ color: cancelColor }} onClick={this.cancel.bind(this)}>{cancelText}</div>:''}
            <div className='confirm' style={{color: confirmColor}} onClick={this.confirm.bind(this)}>{confirmText}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Modal