
import 'babel-polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import VConsole from 'vconsole'
import {init} from 'ckt_oss'
import './assets/less/reset.less';
import './assets/less/animate.less';

import App from './App.jsx';

import service from './config/request'
import GBCKT from './config/globalData'
import wx from './config/jweixin-1.4.0';
import injectCompAPI from './config/globalAPI'
import {
  setWpsBtnType,
  setUserInfo,
  setWpsUserInfo,
  initWpsDw,
  eventBus,
  setWpsViptabPosition,
  setIsWechatMin
} from './config/eventBus';

require('./assets/js/dw-min-2.6.2');
require('intersection-observer') // IntersectionObserver兼容低版本

// 托管包 先走OSS配置，init会走后端默认COS配置
init()
injectCompAPI()
React.axios = service
React.wx = wx
window.GBCKT = GBCKT

const {isWps, isWechat} = eventBus.state

// eslint-disable-next-line
let ENV = API_ENV ? API_ENV : process.env.API_ENV;
// 插入vconsole
if (ENV != 'prod') {
  import('vconsole').then(() => {
    new VConsole()
  })
}

const renderDom = () => {
  ReactDOM.render(<App />, document.getElementById('root'))
  console.log('renderDom-renderDom-renderDom');
};

const ready = () => {
  const initWpsInfo = async() => {
    console.log('initWpsInfo=start');
    setWpsBtnType()
    setWpsUserInfo()
    await setUserInfo()
    await initWpsDw()
    renderDom()
  }
  if (window.__wpsoffice_environment === 'miniprogram') { // jssdk可以自动注入的wps客户端高版本
    if (window.wps && window.wps.onAppShow) {
      let flag = false
      window.wps.onAppShow((res) => {
        if (window.wps.offAppShow) {
          window.wps.offAppShow()
        }
        setWpsViptabPosition(res.referrerInfo.extraData.position || 'null')
        flag = true
        initWpsInfo()
      })
      if (!!eventBus.state.loginCode) { // 来源：wps自己开发新版页面
        initWpsInfo()
      } else { // 来源：咱们自己开发的页面
        setTimeout(() => {
          if (!flag) {
            setWpsViptabPosition(window.localStorage.getItem('wpsViptabPosition') || 'null')
            initWpsInfo()
          }
        }, 1000)
      }
    } else {
      setWpsViptabPosition('null')
      initWpsInfo()
    }
  } else { // jssdk需要手动引入的wps客户端底版本
    import('./assets/js/wpsoffice_sdk.min').then(() => {
      setWpsViptabPosition('null')
      initWpsInfo()
    })
  }
}

const initApp = async() => {
  if (isWps) {
    // wps客户端逻辑
    if (window.wps) {
      ready()
    } else {
      document.addEventListener('WpsofficeSDKReady', ready, false)
    }
  } else if (isWechat) {
    // 判断是否为微信小程序
    React.wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        setIsWechatMin(true)
      }
    })
    await initWpsDw()
    setUserInfo()
    renderDom()
  } else {
    setUserInfo()
    renderDom()
  }
}

initApp()
