import React from 'react'
import './index.less'
import request from '../../config/request';

import Sortable from 'react-sortablejs';

import wpsContainer from '../../components/wpsContainer/index';
import login from '../../components/login/index';
@login
@wpsContainer()
class DiySort extends React.Component {
  state = {
    mobileThemes: [],
    myThemes: [],
    otherThemes: []
  }
  componentDidMount() {
    this.getDesignKindBasicInfo()
  }
  // 获取移动端自定义场景
  getDesignKindBasicInfo() {
    request.get('getDesignKindBasicInfo', {type: 2}).then((res) => {
      console.log(res)
      if (res.data.body.code === 200) {
        this.setState({
          mobileThemes: res.data.body.data.secondLabel
        }, () => {
          this.getWeChatFavoriteIds()
        })
      }
    })
  }
  //获取用户收藏的场景id
  getWeChatFavoriteIds() {
    request.get('getWeChatFavoriteIds').then((res) => {
      const code = res.data.body.code
      if (code === - 2) {
        request.login()
      } else if (code === 200) {
        const myThemeIds = res.data.body.data.split(',')
        const mobileThemes = this.state.mobileThemes
        const mobileThemesmap = {}
        mobileThemes.forEach((item) => {
          mobileThemesmap[item.kindId] = item
        })
        const myThemes = []
        myThemeIds.forEach((item) => {
          if (mobileThemesmap[item]) {
            myThemes.push(mobileThemesmap[item])
            delete mobileThemesmap[item]
          }
        })
        this.setState({
          myThemes,
          otherThemes: Object.values(mobileThemesmap)
        })
      }
    })
  }
  // 保存
  saveFavorites = () => {
    const ids = this.state.myThemes.map((item) => item.kindId).join(',')
    request.get('saveFavorites', {ids}).then((res) => {
      const code = res.data.body.code
      if (code === 200) {
        this.props.history.push('/tabBar/home')
      } else if(code === 0) {
        React.showToast({
          title: '保存失败请稍后再试'
        })
      } else if (code === -2) {
        request.login()
      }
    })
  }
  onSortChange(newSort) {
    const oldSort = this.state.myThemes
    const oldSortMap = {}
    oldSort.forEach((item) => {
      oldSortMap[item.kindId] = item
    })
    const newMyThemes = []
    newSort.forEach(item => newMyThemes.push(oldSortMap[item]))
    this.setState({
      myThemes: newMyThemes
    })
  }
  // 删除场景
  deleteTheme = (ev, item) => {
    ev.stopPropagation()
    const kindId = item.kindId
    this.setState((pre) => {
      pre.otherThemes.push(item)
      const myThemes = pre.myThemes.filter((item) => item.kindId !== kindId)
      return {
        myThemes,
        otherThemes: pre.otherThemes
      }
    })
  }
  // 增加场景
  addTheme = (item) => {
    const kindId = item.kindId
    this.setState((pre) => {
      pre.myThemes.push(item)
      const otherThemes = pre.otherThemes.filter((item) => item.kindId !== kindId)
      return {
        myThemes: pre.myThemes,
        otherThemes
      }
    })
  }
  render() {
    const {myThemes, otherThemes} = this.state
    return <div className='diy-sort-con'>
      <div className='my-theme-title'>
        <p className='left-title'>我的场景<span>手动排序（长按拖拽排序）</span></p>
        <span className='right-title' onClick={this.saveFavorites}>完成</span>
      </div>
      <Sortable className='themes-con' options={{ animation: 180, ghostClass: 'clone' }} onChange={this.onSortChange.bind(this)}>
        {myThemes.map((item) => {
          return <div className='theme-item' data-id={item.kindId} key={item.kindId}>
            <span>{item.kindTitle}</span>
            <span className='deleteBtn' onTouchStart={(ev) => this.deleteTheme(ev, item)}></span>
          </div>
        })}
      </Sortable>
      {
        otherThemes.length > 0 && <>
          <div className='my-theme-title'>
            <p className='left-title'>点击添加更多场景</p>
          </div>
          <div className='themes-con'>
            {otherThemes.map((item) => {
              return <div className='theme-item' key={item.kindId}>
                <span>{item.kindTitle}</span>
                <span className='addBtn' onClick={() => this.addTheme(item)}></span>
              </div>
            })}
          </div>
        </>
      }
    </div>
  }
}
export default DiySort