
import React from 'react'
import ThemeHead from './themeHead'
import ThemeContent from './themeContent'
import './index.less'

const ThemeItem = (props) => {
  const headProps = {
    title: props.data.title,
    subTitle: props.data.subTitle,
    id: props.data.id,
    onGoMore: props.onGoMore
  }
  const contentProps = {
    title: props.data.title,
    templates: props.data.templates,
    extstyle: props.data.extstyle
  }
  return (
    <div className='themeBlock'>
      <ThemeHead {...headProps} />
      <ThemeContent {...contentProps} />
    </div>
  )
}
export default ThemeItem