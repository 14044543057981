import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './index.less'

class Image extends React.Component{
  static propTypes = {
    src: PropTypes.string,
    load: PropTypes.func,
    lazyLoad: PropTypes.bool,
    className: PropTypes.string
  }
  static defaultProps = {
    load: () => {},
    lazyLoad: false,
    style: {}
  }
  state = {
    isLoad: false,
    isRenderImg: false
  }
  observer = {}
  imgRef = React.createRef()
  componentDidMount() {
    this.handelImgLazyLoad()
  }
  componentWillUnmount() {
    this.observer.disconnect && this.observer.disconnect()
  }
  imgLoad = () => {
    this.setState({
      isLoad: true
    })
    this.props.load()
  }
  // 处理图片懒加载
  handelImgLazyLoad = () => {
    if (this.props.lazyLoad) {
      this.observer = new IntersectionObserver((entries) => {
        this.setState({
          isRenderImg: entries[0].isIntersecting
        })
      }, {rootMargin: '300px 0px'})
      this.observer.observe(this.imgRef.current);
    } else {
      this.setState({
        isRenderImg: true
      })
    }
  }
  render() {
    const { isLoad, isRenderImg } = this.state
    const {className, src, lazyLoad, load, ...reset } = this.props
    const imgClass = classnames(className, 'custom-image', {'custom-image-hasLoad': isLoad })
    return <img className={imgClass} ref={this.imgRef} onLoad={this.imgLoad} src={isRenderImg ? src : null} {...reset} alt=""/>
  }
}

export default Image