import React from 'react'
import classnames from 'classnames';
import wpsContainer from '../../components/wpsContainer/index';
import Head from './components/collectionHead'
import fixedColWaterFall from '../../utils/fixedColWaterFall';
import ItemElement from '../../components/itemElement/index.jsx'
import ListContainer from '../../components/listContainer/index';
import BottomTips from '../../components/bottomTips'
import EmptyTips from './components/emptyTips'
import Loading from '../../components/loading'
import { scrollToTop ,isTablet } from '../../utils/tools'
import { COLLECT_PAGE_CLASS } from  '../../config/constant'
import login from '../../components/login';

import './index.less'

@login
@wpsContainer({name: 'favorpage_show'}, {vipBar: 'viptab_click', vipParam: {position: 'favorpage'}})
class Collection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      kindList: [], // 场ོ景ོ列ོ表ོ
      templateUnOrderList:[],//原始模板列表
      templateList: [], // 瀑布流模板列表
      templateListH: 0, // 模板列表容器高度
      isBottomShow: false, // 尾页
      isInDelect: false, // 是否处于删除状态
      isDeleteBtnSHow: false, // 是否显示底部删除按钮
      isEmptyShow: false, // 是否展示空页面提示
      isToTopShow: true, // 在需要时隐藏
      currentScreenWidth:0
    }
    this.pageParams = {
      kindId: 0,
      pageNo: 0,
      pageSize: 10,
    }
    this.deleteIds = new Set() // 批量删除id
    this.totalCount = 0 // 收藏模板总数量
    this.isLoading = false
    this.scrollWrap = null // 滚动容器
    this.headEle=null

    this.reachBottom = this.reachBottom.bind(this)
    this.toggoleDeleteState = this.toggoleDeleteState.bind(this)
    this.deleteTemp = this.deleteTemp.bind(this)
    this.toggoleKind = this.toggoleKind.bind(this)
    this.showUneditableTips = this.showUneditableTips.bind(this)
    this.orientationHandler=this.getTemplateLayoutList.bind(this)
  }
  componentDidMount() {
    this.scrollWrap = document.getElementsByClassName(COLLECT_PAGE_CLASS)[0]
    this.reloadTemplateList()
    this.loadScene()
    window.addEventListener('orientationchange',this.orientationHandler)
  }
  componentWillUnmount(){
    window.removeEventListener('orientationchange',this.orientationHandler)
  }
  reachBottom() {
    if (this.totalCount <= this.state.templateList.length) return
    if (this.isLoading) return
    this.fetchCollectList(true)
  }
  onRef(ref) {
    this.headEle = ref
  }
  // 获ོ场ོ景ོ列ོ表ོ
  async loadScene() {
    const res = await React.axios.post('getCollectTemplateScene')
    const { code, data: kindList} = res.data.body
    if (code === 200) {
      let totalCount = 0
      kindList.forEach((item) => {
        totalCount += item.templateCount
      })
      kindList.unshift({
        kindTitle: '全部场景', kindId: 0, templateCount: totalCount
      })
      this.setState({
        kindList,
      })
    } else {
      this.setState({
        isEmptyShow: true,
        templateList: [],
        kindList: []
      })
    }
  }
  getTemplateLayoutList(){
    //手机屏2列 pad竖屏3列 pad横屏4列
    let listSum=2
    if(isTablet()){
      console.log('window.orientation',window.orientation)
      if(window.orientation === 90 || window.orientation === -90){ //横向
        listSum=4
      }else{ //纵向
        listSum=3
      }
    }
    let screenWidth
    if(listSum===4){
      screenWidth=window.screen.height>window.screen.width?window.screen.height:window.screen.width
    }else{
      screenWidth=window.screen.width
    }
    this.setState({
      currentScreenWidth:screenWidth
    })
    const marginX=24 / 750 * screenWidth
    const marginY = 21 / 750 * screenWidth
    const mainwidth=screenWidth-2*marginX //减去外部两侧间距
    const {waterFallList, waterFallHeight} = fixedColWaterFall({
      eleData: this.state.templateUnOrderList,
      listSum: listSum,
      boxRect: {
        width: mainwidth / listSum - marginX + marginX / listSum, //减去内侧边距
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        marginLeft: marginX,
        marginTop: marginY
      }
    })
    this.setState({
      templateListH:waterFallHeight,
      templateList: waterFallList
    })
  }
  // 获取收藏列表
  async fetchCollectList(isloadMore = false) {
    this.isLoading = true
    this.pageParams.pageNo++
    const res = await React.axios.post('getWpsCollectTemplateList', this.pageParams)
    this.isLoading = false
    const { code, totalCount } = res.data.body // -101参数错误;-102未登录;-103无模板记录;200成功

    if (code === 200) {
      const { templates, totalCount } = res.data.body.data
      const limitSceneIds = ['35', '31', '127', '128'] // 不可编辑模板场景id限制
      templates.forEach(item => {
        item.canEdit = limitSceneIds.indexOf(`${item.designKindId}`) === -1
      })
      this.totalCount = totalCount
      if (isloadMore) { // 尾页
        this.setState({
          templateUnOrderList:[...this.state.templateUnOrderList,...templates],
          isBottomShow:true
        })
      }else{
        this.setState({
          templateUnOrderList:templates
        })
      }
      this.getTemplateLayoutList()
    }else if (code === -102) {
      React.axios.login()
    }else if (code === -101) {
      React.showModal({
        title: '提示',
        content: '参数错误',
      })
    }else if (code === -103 || (totalCount===0)) {
      this.setState({
        isEmptyShow: true
      })
    }
  }
  showUneditableTips(e) {
    React.showModal({
      title: '提示',
      content: 'WPS-PC客户端打开-图片设计',
      showCancel: false,
      confirmText: '我知道了',
    })
    e.stopPropagation()
  }
  // 进入和取消删除操作
  toggoleDeleteState(status) {
    this.state.templateList.forEach(item => {
      item.isInDel = false
    })
    this.deleteIds.clear()
    this.setState({
      isInDelect: status,
      isDeleteBtnSHow: false,
      isToTopShow: !status
    })
  }
  // 批量删除收藏模板
  async deleteTemp() {
    // 1. 未登录：去登录
    const { isLogined } = window.GBCKT
    if (!isLogined) { 
      React.axios.login()
      return
    }

    // 2. 已登录：改变收藏收藏列表、视图状态、数据
    const ids = [...this.deleteIds].join(',')
    const res = await React.axios.post('unCollect', { ids })
    const { code } = res.data.body
    if (code === -2) { // 登录状态失效
      React.axios.login()
    } else if (code === 1) {
      if (this.deleteIds.size === this.state.templateList.length) { // 该场景模板全部删除
        this.headEle.toggoleKind(0)
      } else {
        scrollToTop(this.scrollWrap)
        this.reloadTemplateList()
      }
      this.loadScene()
      
      const { likeSet } = window.GBCKT
      this.deleteIds.forEach(idItem => { likeSet.delete(idItem) })
      this.deleteIds.clear()
      this.setState({
        isInDelect: false,
        isDeleteBtnSHow: false,
        isBottomShow: false
      })
    } else {
      React.showModal({
        title: '提示',
        content: '操作频繁请稍候重试',
      })
    }
  }
  // 切换分类
  toggoleKind(kindId) {
    this.setState({
      isEmptyShow: false
    })
    scrollToTop(this.scrollWrap)
    this.pageParams.kindId = kindId
    this.reloadTemplateList()
  }
  // 重新加载第一页
  reloadTemplateList() {
    this.pageParams.pageNo = 0
    this.fetchCollectList()
  }
  // 更新正在操作的删除id列表
  updateDeleteList(index) {
    const { templateList } = this.state
    const templateItem = templateList[index]
    const prevItemState = templateItem.isInDel
    if (this.deleteIds.size >= 20 && !prevItemState) { // 
      React.showToast({title: '单次操作最多可选20个'})
      return
    }

    const stateObj = {}
    templateItem.isInDel = !prevItemState
    if (templateItem.isInDel) {
      this.deleteIds.add(templateItem.designTemplateId)
    } else {
      this.deleteIds.delete(templateItem.designTemplateId)
    }
    if (this.deleteIds.size>0) {
      stateObj.isDeleteBtnSHow = true
    } else {
      stateObj.isDeleteBtnSHow = false
    }
    stateObj.templateList = this.state.templateList
    this.setState(stateObj)
  }
  // 渲染模板
  renderTemplateList() {
    const { templateList, templateListH, isInDelect ,currentScreenWidth} = this.state
    const marginX=24 / 750 * currentScreenWidth
    const designListConStyle = {height: templateListH ,marginLeft:marginX,marginRight:marginX}
    return (
      <div className='templateWrap' style={designListConStyle}>
        {templateList.map((templateItem, tempalteIndex) => {
          const {top, left, itemWidth, imgHeight} =templateItem.boxStyle
          const imgConStyle = {top,left,width:itemWidth,height:imgHeight}
          const deleteClass = classnames({
            deleteMask: true, 
            active: templateItem.isInDel
          })
          return (
            <div className='templateItem' style={imgConStyle} key={templateItem.designTemplateId}>
              {isInDelect ? (
                <div
                  className={deleteClass}
                  style={{width:'100%',height:'100%'}}
                  onClick={this.updateDeleteList.bind(this, tempalteIndex)}
                >    
                </div>
              ) : ('')}
              {/* {!templateItem.canEdit ? (
                <div
                  className='uneditable'
                  style={{width:'100%',height:'100%'}}
                  onClick={this.showUneditableTips}
                >
                  <div className="tip-con">
                    <div className='text'>该模板类型</div>
                    <div className='text'>仅能在电脑上编辑</div>
                  </div>
                </div>
              ):('')} */}
              <ItemElement
                data={templateItem}
                imgStyle={{ width: '100%', height: '100%'}}
                wpsDw={{ name: 'favorpage_template_click', param: {position: 'favorpage', data3: `${templateItem.templateTitle}_${templateItem.designTemplateId}`}}}
                imgKey='designTemplateImageUrl'
              />
            </div>
          )
        })}
      </div>
    )
  }
  // 渲染删除按钮
  renderDeleteBtn() {
    return (
      <div onClick={this.deleteTemp} className="collectDeleteBtn">
        <span>取消收藏</span>
      </div>
    )
  }
  render() {
    const { templateList, isBottomShow, isInDelect, isDeleteBtnSHow, isEmptyShow, kindList, isToTopShow } = this.state
    const headProps = {
      kindList,
      isInDelect
    }
    return (
      <div className='collectionFixedWrap'>
        <div className={COLLECT_PAGE_CLASS}>
          {isEmptyShow ? ('') : (
            <Head {...headProps} onChangeDelete={this.toggoleDeleteState} onChangeKind={this.toggoleKind} onRef={this.onRef.bind(this)} />
          )}
          <ListContainer reachBottom={this.reachBottom} isToTopShow={isToTopShow}>
            {this.renderTemplateList()}
          </ListContainer>
          {(templateList.length >= 10 && !isBottomShow) ? <Loading /> : ('')}
          {(templateList.length >= 10 && isBottomShow) ? <BottomTips /> : ('')}
          {isEmptyShow ? <EmptyTips /> :('')}
        </div>
        {isDeleteBtnSHow ? this.renderDeleteBtn() : ('')}
      </div>
    )
  }
}

export default Collection