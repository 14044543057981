
import React from 'react'
import './index.less'
import PropTypes from 'prop-types';
import ItemElement from '../../../../components/itemElement/index'
import { pxTransform, wpsDw } from '../../../../utils/tools'
import { withRouter } from "react-router-dom"

class ThemeBlockItem extends React.Component { 
  static propTypes = {
    themeBlockItem: PropTypes.object,
  }
  static defaultProps = {
    themeBlockItem: {},
  }
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  renderHead() {
    const { themeBlockItem } = this.props
    return (
      <div className='themeHead'>
        <h3>{themeBlockItem.themeName}</h3>
        <span onClick={() => {
          wpsDw('button_click', 'hometab_moretemplate_click', {data2: `${themeBlockItem.themeName}_${themeBlockItem.themeId}`})
          this.props.history.push({ pathname: `/themeDetail`, state: { id: themeBlockItem.themeId, fromType: 'home', title: themeBlockItem.themeName } })
        }}>全部模板</span>
      </div>
    )
  }
  renderContent() { 
    const { themeBlockItem, isGuideShow } = this.props
    let templates = themeBlockItem.templates
    if (templates.length === 0) return ('') // 空数据处理
    let { width, height } = templates[0]
    const ratio = parseInt(width) / parseInt(height)
    const isWideImg = ratio > 1.2
    if (isWideImg) {
      templates = templates.slice(0, 6)
    }
    width = isWideImg ? 340 : 220
    height = isWideImg ? 340 / ratio : 220 / ratio
    const imgStyle = {
      width: pxTransform(width),
      height: pxTransform(height),
      margin: pxTransform(10),
    }
    if (templates && templates.length === 0) return ('') // 无模板不显示
    return <>
      {templates.map((templateItem, templateIndex) => (
        <div style={{ display: 'inline-block', position: 'relative' }} key={templateItem.designTemplateId}> 
          <ItemElement
            wpsDw={{ name: 'hometab_template_click', param: {
              position: 'hometab',
              data2: `${this.props.themeBlockItem.themeName}_${this.props.themeBlockItem.themeId}`,
              data3: `${templateItem.templateTitle}_${templateItem.designTemplateId}`
            }}}
            showGuide={isGuideShow && templateIndex === 0}
            data={templateItem}
            imgStyle={imgStyle}
            onCloseGuide={this.closeGuide}
            />
        </div>
      ))}
    </>
  }
  render() {
    return (
      <div className='themeBlockItem'>
        {this.renderHead()}
        {this.renderContent()}
      </div>
    )
  }
}
export default withRouter(ThemeBlockItem)