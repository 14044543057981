import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ItemElement from '../../../../components/itemElement/index'
import { withRouter } from "react-router-dom"
import { nodeAlign } from '../../../../utils/tools'
import { pxTransform } from '../../../../utils/tools'

import './index.less'
class KindBlockItem extends React.Component {
  static propTypes = {
    kindBlockItem: PropTypes.object,
  }
  static defaultProps = {
    kindBlockItem: {},
  }
  constructor(props) {
    super(props)
    this.state = {
      currentNavIndex: 0,
      templateList: this.props.kindBlockItem.templates,
      navList: [{ title: '推荐', tagId: 0,},...this.props.kindBlockItem.tags],
      kindName: this.props.kindBlockItem.kindName,
      isGuideShow: !Boolean(window.localStorage.getItem('hasGuided'))
    }
    this.selecTemp = this.props.kindBlockItem.templates // 缓存精选模板列表
    this.goToSecondPage = this.goToSecondPage.bind(this)
  }
  changeNav(navIndex) {
    const { navList } = this.state
    if (navIndex===0) {
      this.setState({templateList: this.selecTemp})
    } else {
      this.reloadTemp(navList[navIndex].tagId)
    }
    this.setState({
      currentNavIndex: navIndex
    })
    nodeAlign(this.navWrapNode , navIndex)
  }
  async reloadTemp(tag_id) {
    const params = {
      tag_id,
      page_no: 1,
      page_size: 9
    }
    const res = await React.axios.post('getTemplateByTag', params)
    const { code } = res.data.body
    if (code === 200) {
      const { data: { templates: templateList } } = res.data.body 
      this.setState({templateList})
    } else {
      this.setState({ templateList:[] })
    }
  }
  renderNav() {
    const { navList } = this.state
    const { currentNavIndex } = this.state

    if (navList.length<=1) return ('')
    
    return (
      <div ref={(navWrapNode) => {
        this.navWrapNode = navWrapNode
      }} className='navWrap'>
        {navList.map((navItem, navIndex) => {
          const itemClass = classnames({
            navItem: true,
            active: currentNavIndex === navIndex
          })
          return (
            <div className={itemClass} key={navItem.tagId} onClick={this.changeNav.bind(this, navIndex)}>{navItem.title}</div>
          )
        })}
      </div>
    )
  }
  goToSecondPage() {
    const state = {
      kindId: this.props.kindBlockItem.kindId,
      kindTitle: this.state.kindName
    }
    this.props.history.push({ pathname: '/secondLabel', state })
  }
  renderHead() {
    const { kindName } = this.state
    return (
      <div className='kindHead'>
        <h3>{kindName}</h3>
        <span onClick={this.goToSecondPage}>全部模板</span>
      </div>
    )
  }
  renderContent() {
    let { templateList } = this.state
    if (templateList.length === 0) return ('') // 空数据处理
    let { width, height } = templateList[0]
    const ratio = parseInt(width) / parseInt(height)
    const isWideImg = ratio > 1.2
    if (isWideImg) {
      templateList = templateList.slice(0, 6)
    }
    width = isWideImg ? 340 : 220
    height = isWideImg ? 340 / ratio : 220 / ratio
    const imgStyle = {
      width: pxTransform(width),
      height: pxTransform(height),
      margin: pxTransform(10),
    }
    const { isGuideShow } = this.props
    return <>
      {templateList.map((templateItem, templateIndex) => (
        <div style={{display: 'inline-block', position: 'relative'}} key={templateItem.designTemplateId}>
          <ItemElement
            wpsDw={{ name: 'hometab_template_click', param: {
              position: 'hometab',
              data1: `${this.props.kindBlockItem.kindName}_${this.props.kindBlockItem.kindId}`,
              data3: `${templateItem.templateTitle}_${templateItem.designTemplateId}`
            }}}
            showGuide={isGuideShow  && templateIndex === 0}
            data={templateItem}
            imgStyle={imgStyle}
            onCloseGuide={this.closeGuide}
          />
        </div>
      ))}
    </>
  }
  render() {
    return (
      <div className='kindBlockItem'>
        {this.renderHead()}
        {this.renderNav()}
        {this.renderContent()}
      </div>
    )
  }
}

export default withRouter(KindBlockItem)