//请不要动这个文件一定不一要动
// eslint-disable-next-line
let ENV = API_ENV ? API_ENV : process.env.API_ENV;
const urlMap = {
  prod: {
    apiV2: 'api-v2.chuangkit.com',
    host: 'api.chuangkit.com',
    webHost: 'm.chuangkit.com',
    GW: 'gw.chuangkit.com',
  },
  moni: {
    apiV2: 'moni-api-v2.chuangkit.com',
    host: 'moniapi.chuangkit.com',
    webHost: 'monim.chuangkit.com',
    GW: 'moni-gw.chuangkit.com',
  },
  beta: {
    apiV2: 'beta-api-v2.chuangkit.com',
    host: 'beta-api.chuangkit.com',
    webHost: 'beta-m.chuangkit.com',
    GW: 'beta-gw.chuangkit.com',
  },
  local: {
    apiV2: 'local-api-v2.chuangkit.com',
    host: 'local-api.chuangkit.com',
    webHost: 'monim.chuangkit.com',
    GW: 'local-gw.chuangkit.com',
  },
}

const {apiV2, host, webHost,GW} = urlMap[ENV]

const urls = {
  webHost,
  // 获取用户信息
  getUserInfo: `https://${GW}/accountcenter/user/getAccountUserInfo.do`,
  //获取用户以设计分类分组的设计数量信息
  getDesignCountGroupByKind: `https://${GW}/team/design/getDesignCountGroupByKind.do`,
  // 获取首页数据的聚合接口（即将废弃）
  getHomePageInformation: `https://${GW}/contentcopyright/designkind/getWxMiniProgramHomePageInformation.do`,
  // 获取用户设计
  queryUserDesigns: `https://${GW}/team/design/queryUserDesigns.do`,
  // 新版获取用户设计 - apiv2
  queryUserDesignsV2: `https://${GW}/team/design/queryUserDesigns.do`,
  // 获取模板精选版块
  getTemplateSelectedBlock: `https://${GW}/contentcopyright/templateSelectedBlock/getTemplateSelectedBlock.do`,
  // 获取精选版块下的模板列表
  getTemplateOfSelectedBlock: `https://${GW}/contentcopyright/templateSelectedBlock/getTemplateOfSelectedBlock.do`,
  // 获取一级分类下所有的标签信息
  getMobileFirstKindAllTag: `https://${GW}/contentcopyright/mobile/getMobileFirstKindAllTag.do`,
  // 通过标签获取模板列表
  getTemplateByTag: `https://${GW}/contentcopyright/mobile/getTemplateByTag.do`,
  // 获取二级分类下所有的标签信息
  getDesignKindTags: `https://${GW}/contentcopyright/mobile/getDesignKindTags.do`,
  // 获取所有一级分类
  getMobileFirstKindsInfo: `https://${GW}/contentcopyright/mobile/getMobileFirstKindsInfo.do`,
  // 一级分类下的二级分类
  getMobileSecondKindsInfo: `https://${GW}/contentcopyright/mobile/getMobileSecondKindsInfo.do`,
  //删除设计模板
  delUserDesign: `https://${GW}/team/design/delUserDesign.do`,
  // wps-移动端-获取wps移动端精选专题
  getWpsMobileFeaturedTopics: `https://${GW}/contentcopyright/template/getWpsMobileFeaturedTopics.do`,
  // wps-移动端-获取wps移动端精选专题下的模板
  getWpsMobileFeaturedTopicTemplates: `https://${GW}/contentcopyright/template/getWpsMobileFeaturedTopicTemplates.do`,
  // 模板取消收藏
  unCollect: `https://${GW}/team/template/user/favorite/unCollect.do`,
  // 模板收藏
  addTempleteLike: `https://${GW}/team/template/user/favorite/collect.do`,
  // 获取自定义二级分类数据
  getDesignKindBasicInfo: `https://${GW}/contentcopyright/designkind/getDesignKindBasicInfo.do`,
  // 收藏的二级分类
  getWeChatFavoriteIds: `https://${GW}/team/designkind/getWeChatFavoriteIds.do`,
  // 保存收藏的二级分类
  saveFavorites: `https://${apiV2}/designkind/operateWeChatFavoriteIds.do`,
  collectTemplate: `https://${GW}/team/template/user/favorite/collect.do`,
  // 模板收藏列表
  getTemplateList: `https://${GW}/team/template/user/favorite/getTemplateList.do`,
  // 模板收藏id集合
  getUserData: `https://${GW}/team/template/user/favorite/getUserData.do`,
  // 获取新版首页推荐tab数据聚合接口
  testHomeData: `https://${GW}/contentcopyright/mobile/getMobileRecommendHomePage.do`,
  //根据设计分类id获取模板信息
  getWpsMobileAllTemplates: `https://${GW}/wps/wps/mobile/getWpsMobileAllTemplates.do`,
  //获取设计分类
  getDesignType: `https://${GW}/team/home/getDesignType.do`,
  // 获取文件夹下的素材
  getMaterialsInFolder: `https://${GW}/team/material/getMaterialsInFolder.do`,
  // 批量删除素材
  delMaterials: `https://${GW}/team/material/delMaterials.do`,
  //获取关键词
  getRecommendKeyword: `https://${GW}/contentcopyright/template/getRecommendKeyword.do`,
  //搜索关键字
  wxSmallAppSearch: `https://${GW}/contentcopyright/template/wxSmallAppSearchV2.do`,
  // 获取用户历史容量
  getUserCapacity: `https://${GW}/team/user/getUserCapacity.do`,
  // 创建设计
  makeDesignId: `https://${host}/design/makeDesignIdV2.do`,
  // wps会员购买预下单地址
  getWpsPrePayBillno: `https://${GW}/wps/pay/pay.do`,
  // 获取用户收藏模板的场景以及数量
  getCollectTemplateScene: `https://${GW}/team/template/user/favorite/getCollectTemplateScene.do`,
  // wps登录
  wpsLogin: `https://${GW}/wps/passport/wpsDirectLogin.do`,
  // 旧版wps登录
  oldWpsLogin: `https://${GW}/wps/login/wpsLogin.do`,
  // 根据token登陆
  loginByToken: `https://${GW}/wps/login/makeWpsLogin.do`,
  // wps-移动端-模块顺序
  getWpsMobileModuleList: `https://${GW}/wps/wps/mobile/getWpsMobileModuleList.do`,
  // 获取新版首页推荐tab模板列表
  getMobileRecommendKindTagTemplate: `https://${GW}/contentcopyright/mobile/getMobileRecommendKindTagTemplate.do`,
  // 获取wps移动端专题列表
  getWpsMobileTheme: `https://${apiV2}/wps/mobile//getWpsMobileTheme.do`,
  // 获取wps移动端首页专题下模板
  getWpsMobileThemeTemplate: `https://${GW}/wps/wps/mobile//getWpsMobileThemeTemplate.do`,
  // 获取wps移动端关键词
  getWpsMobileKeyword: `https://${apiV2}/template/getWpsMobileKeyword.do`,
  // 获取wps移动端首页推荐分类下的模板
  getWpsMobileRecommendKindTemplate: `https://${apiV2}/wps/mobile/getWpsMobileRecommendKindTemplate.do`,
  // 获取wps移动端专题下更多模板
  getWpsMobileThemeMoreTemplate: `https://${apiV2}/wps/mobile/getWpsMobileThemeMoreTemplate.do`,
  // 获取小程序banner
  getWeChatHomeBanner: `https://${apiV2}/designkind/getWeChatHomeBanner.do`,
  // 获取新缓存的精选模板
  getWxAppSelectedTemplateInfo: `https://${GW}/contentcopyright/designtemplate/getWxAppSelectedTemplateInfo.do`,
  // 获取wps移动端用户收藏模板列表
  getWpsCollectTemplateList: `https://${GW}/team/template/user/favorite/getWpsCollectTemplateList.do`,
  // 刷新wps用户信息
  wpsRefreshUserInfo: `https://${GW}/wps/user/wpsRefreshUserInfo.do`,
  // 获取wps用户信息
  getWpsUserInfo: `https://${host}/user/getWpsUserInfo.do`,
  // 上报活跃用户数据
  reportWpsActive: `https://${GW}/wps/user/reportWpsActive.do`,
  // 判断是否支持编辑
  getDesignOpenSwitch: `https://${GW}/wps/design/designOpenSwitch.do`,
}

export default urls
