/* eslint-disable react/jsx-indent-props */
import React from 'react'
import './index.less'

const BottomTips = () => (
  <div className='bottom-end-text-wrap' >
    <div className='bottom-end-text-box'>
      <div className='b-end-left-line'></div>
      <span className='text'>喂喂，你碰到我的底线了</span>
      <div className='b-end-right-line'></div>
    </div>
  </div>
)
export default BottomTips