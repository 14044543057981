
import React from 'react'
import './index.less'
import { pxTransform, wpsDw } from '../../../../../utils/tools'
import RubberStop from "../../../../../components/rubberStop";

const contentWrap = 'contentWrap', cateRightWrap = 'cateRightWrap'

@RubberStop(`.${contentWrap}`, `.${cateRightWrap}`)
class CateContent extends React.Component { 
  gotoDetail(cateDetailItem, secondLevel) {
    const { keyword } = cateDetailItem
    wpsDw('button_click', 'categorytab_label_click', {
      data1: this.props.firstLevel,
      data2: secondLevel,
      data3: keyword,
    })
    const url = '/search'
    const params = {
      type: 'cate',
      kw: keyword,
      pos: 'categorytablabelsearch'
    }
    this.props.gotoDetail(url, params)
  }
  renderWordItem(cateDetailItem, secondLevel) {
    const longWordStyle = {
      width: 'auto',
      padding: `${pxTransform(19)}  ${pxTransform(27)}`
    }
    let style = {}
    if (cateDetailItem.secondKindName.length > 4) {
      style = longWordStyle
    }
    return (
      <span
        key={cateDetailItem.id}
        style={style}
        onClick={this.gotoDetail.bind(this, cateDetailItem, secondLevel)}
      >{cateDetailItem.secondKindName}</span>
    )
  }
  render() {
    const { data: cateItem } = this.props
    return (
      <div className={cateRightWrap}>
        <div className={contentWrap}>
          {cateItem.map((cateGroupItem) => (
            <div className="rightGroup" key={cateGroupItem.id}>
              <span className="rightTitle">{cateGroupItem.firstKindName}</span>
              <div className="rightWordGroup">
                {cateGroupItem.secondKinds.map(cateDetailItem => this.renderWordItem(cateDetailItem, cateGroupItem.firstKindName))}
              </div>
            </div >
          ))}
        </div>
      </div>
    )
  }
}
export default CateContent