/* eslint-disable react/jsx-indent-props */
import React from 'react'
import classnames from 'classnames';
import cate0 from '../../../../../assets/images/pub/cate0.png'
import cate1 from '../../../../../assets/images/pub/cate1.png'
import cate2 from '../../../../../assets/images/pub/cate2.png'
import cate3 from '../../../../../assets/images/pub/cate3.png'
import cate4 from '../../../../../assets/images/pub/cate4.png'
import './index.less'
const iconCofig = {
  cate0,
  cate1,
  cate2,
  cate3,
  cate4,
}
export default class CateNav extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0
    }
    const defaultActive = this.props.defaultActive
    if (defaultActive) {
      this.state.currentIndex = defaultActive
    }
  }
  toggoleCate(activeIndex) {
    const prevIndex = this.state.currentIndex
    if (prevIndex === activeIndex) return
    this.setState({
      currentIndex: activeIndex
    })

    this.props.onClick(activeIndex)
  }
  render() {
    const { data: cateList } = this.props
    const { currentIndex } = this.state
    const sceneClass = classnames({
      borderRB: currentIndex === 1,
      active: currentIndex === 0,
      cateLetfItem: true
    })
    const leftOverClass = classnames({
      borderRT: currentIndex + 1 === cateList.length + 1,
      leftover: true
    })
    return (
      <div className='cateLeftWrap'>
        <div
          className={sceneClass}
          onClick={this.toggoleCate.bind(this, 0)}
        >
          <img className="itemIcon" src={cate0} alt='' />
          <span className="title">场景</span>
        </div>

        {cateList.map((leftItem, leftIndex) => {
          const kindClass = classnames({
            borderRT: currentIndex + 1 === leftIndex + 1,
            borderRB: currentIndex === 1,
            active: currentIndex === leftIndex + 1,
            cateLetfItem: true
          })
          return (
            <div
              className={kindClass} key={leftIndex + 1}
              onClick={this.toggoleCate.bind(this, leftIndex + 1)}
            >
              <img className="itemIcon" src={iconCofig[leftItem.imgSrc]} alt='' />
              <span className="title">{leftItem.tagName}</span>
            </div>
          )
        })}
        
        <div className={leftOverClass}></div>
      </div>
    )
  }
}
// export default LeftNav