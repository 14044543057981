import React from 'react'
// import wpsContainer from '../../components/wpsContainer/index';
import urls from '../../config/urls';
import {eventBus} from '../../config/eventBus';

// @wpsContainer()
class Contact extends React.Component {
  state = {
    src: null
  }
  componentDidMount() {
    const {userInfo: {userId, nickname}, platform} = eventBus.state
    const data = {
      name: nickname,
      device: platform
    }
    this.setState({
      src: `https://${urls.webHost}/file/html/wpsKefu/index.html?from=wps&uid=${userId}&data=${JSON.stringify(data)}`
    })
    
  }
  render() {
    const {src} = this.state
    const style = {
      'width': '100%',
      'height': '100%',
      'border': 'none'
    }
    return <iframe title='图片设计' src={src} style={style} />
  }
}

export default Contact